import jsPDF from "jspdf";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import startCase from "lodash/startCase";
import moment from "moment";

export const RecipientDestinationSection = (
  params: {
    doc: jsPDF | any;
    bookingType: string;
    accountType: string;
    data: PrintDataEntitas;
    pieceData: any;
    barcode: string;
    vendorRefCode: string;
    barcodeValue: string;
    vendorRefCodeValue: string;
    logo: string;
    shipmentId: string;
    startX: number;
    startY: number;
  },
  estimateSlaParam: string,
  translate: Function,
  language: string
) => {
  const isJumbopackH2h = params.data.isHubToHub;

  const leftMargin = params.startX + 59;
  const recipientPositionY = params.startY + (isJumbopackH2h ? 121.5 : 123);

  const breakdownPositionY = recipientPositionY + 7;
  const breakdownPositionYL2 = breakdownPositionY + 2.25;

  params.doc.setFontSize(5);
  params.doc.setFont("Montserrat", "bold");
  params.doc.text(
    startCase(translate("receipt.receiver")),
    leftMargin + 2,
    breakdownPositionY
  );
  params.doc.text(
    startCase(translate("receipt.destination")),
    leftMargin + 2,
    breakdownPositionYL2
  );

  const sttRecipient =
    params.data.sttRecipientName.length > 20
      ? `${params.data.sttRecipientName.substring(0, 20)}...`
      : params.data.sttRecipientName;

  const sttDestinationDistrictNameString =
    params.data.sttDestinationDistrictName.length > 40
      ? `${params.data.sttDestinationDistrictName.substring(0, 40)}...`
      : params.data.sttDestinationDistrictName;

  const districtString = params.doc.splitTextToSize(
    sttDestinationDistrictNameString,
    28
  );

  params.doc.setFont("Montserrat", "normal");
  params.doc.text(":", leftMargin + 14, breakdownPositionY);
  params.doc.text(":", leftMargin + 14, breakdownPositionYL2);
  params.doc.text(sttRecipient, leftMargin + 15, breakdownPositionY);
  params.doc.text(districtString, leftMargin + 15, breakdownPositionYL2);

  const districtHeight = params.doc.getTextDimensions(districtString).h;
  const breakdownPositionYL3 = breakdownPositionYL2 + districtHeight + 0.25;
  const breakdownPositionYL4 = breakdownPositionYL3 + 2.25;

  params.doc.setFont("Montserrat", "bold");
  params.doc.text(
    startCase(translate("receipt.createdAtV2")),
    leftMargin + 2,
    breakdownPositionYL3
  );
  params.doc.text(
    startCase(translate("receipt.estimation")),
    leftMargin + 2,
    breakdownPositionYL4
  );

  const newSttCreatedAtDate = new Date(params.data.sttCreatedAt);
  const sttCreatedAt = new Intl.DateTimeFormat("en-GB").format(
    newSttCreatedAtDate
  );

  params.doc.setFont("Montserrat", "normal");
  params.doc.text(":", leftMargin + 14, breakdownPositionYL3);
  params.doc.text(":", leftMargin + 14, breakdownPositionYL4);
  params.doc.text(sttCreatedAt, leftMargin + 15, breakdownPositionYL3);

  const estimateSla = moment(estimateSlaParam, "DD MMM YYYY").format("DD/MM/YYYY");

  params.doc.text(estimateSla, leftMargin + 15, breakdownPositionYL4);

  // Instruksi
  if (isJumbopackH2h) {
    const breakdownPositionYL5 = breakdownPositionYL4 + 2.25;
    params.doc.setFont("Montserrat", "bold");
    params.doc.text("Instruksi", leftMargin + 2, breakdownPositionYL5);
    params.doc.setFont("Montserrat", "normal");
    params.doc.text(":", leftMargin + 14, breakdownPositionYL5);
    params.doc.text(
      "Paket diambil di agen",
      leftMargin + 15,
      breakdownPositionYL5
    );
  }
};
