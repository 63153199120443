/* eslint-disable @typescript-eslint/camelcase */
import { ThermalReceiptData } from "@/domain/entities/Printer";
import moment from "moment";
import { createI18n } from "vue-i18n";
import en from "@/i18n/locales/en";
import id from "@/i18n/locales/id";
import my from "@/i18n/locales/my";
import { startCase } from "lodash";
import { ellipsisString } from "./Utils";
import {
  codDfodLabel,
  estimationBound,
  formatPrice
} from "@/app/ui/modules/receipt-v2/module";
import { isCourierRecommendation } from "@/app/ui/modules/receipt-v2/module";
import { FlagsPermissionPrintReceipt } from "@/feature-flags/flags-permission-print-receipt";
import { FlagsFeatureShipmentC1 } from "@/feature-flags/flags-shipment-c1";
import {
  BOSSPACK_WATERMARK,
  NEW_COD_WATERMARK,
  DG_WATERMARK,
  SAMEDAY_WATERMARK
} from "@/app/ui/assets/string/thermal";

let i18nInstance: any;
let translate: any;

const formatDecimal = (value: string) => value.replace(/\./g, ",");
const productLength: { [x: number]: number } = {
  3: 60,
  4: 35,
  5: 10
};

export const formatTextPrnV2 = (data: ThermalReceiptData) => {
  i18nInstance = createI18n({
    legacy: false,
    locale: data.language,
    fallbackLocale: "id",
    globalInjection: false,
    messages: {
      en,
      id,
      my
    }
  });
  translate = i18nInstance.global.t;

  const isSTTCAOrRetail =
    data.sttBookedForName.toLowerCase() === "customer apps" ||
    data.sttNumber.startsWith("11LP") ||
    data.sttNumber.startsWith("10LP");

  const specialProduct = ["boss", "same"].includes(
    data.sttProductShort.toLowerCase()
  );

  const startX = 40;
  let text = "",
    x = startX,
    yLeft = 0,
    yRight = 0;

  text = "^XA^MMT^PW799^LL1199^LS0\n";

  if (
    data.sttProduct === "BOSSPACK" &&
    data.sttIsCod &&
    data.isDangerousGoods
  ) {
    text += BOSSPACK_WATERMARK;
    text += NEW_COD_WATERMARK;
    text += DG_WATERMARK;
  } else if (
    data.sttProduct === "SAMEDAY" &&
    data.sttIsCod &&
    !data.isDangerousGoods
  ) {
    text += SAMEDAY_WATERMARK;
    text += NEW_COD_WATERMARK;
  } else if (
    data.sttProduct === "SAMEDAY" &&
    !data.sttIsCod &&
    data.isDangerousGoods
  ) {
    text += SAMEDAY_WATERMARK;
    text += DG_WATERMARK;
  } else if (
    data.sttProduct === "SAMEDAY" &&
    !data.sttIsCod &&
    !data.isDangerousGoods
  ) {
    text += SAMEDAY_WATERMARK;
  } else if (
    data.sttProduct === "SAMEDAY" &&
    data.sttIsCod &&
    data.isDangerousGoods
  ) {
    text += SAMEDAY_WATERMARK;
    text += NEW_COD_WATERMARK;
    text += DG_WATERMARK;
  } else if (
    data.sttProduct === "BOSSPACK" &&
    data.sttIsCod &&
    !data.isDangerousGoods
  ) {
    text += BOSSPACK_WATERMARK;
    text += NEW_COD_WATERMARK;
  } else if (
    data.sttProduct === "BOSSPACK" &&
    !data.sttIsCod &&
    data.isDangerousGoods
  ) {
    text += BOSSPACK_WATERMARK;
    text += DG_WATERMARK;
  } else if (
    data.sttProduct === "BOSSPACK" &&
    !data.sttIsCod &&
    !data.isDangerousGoods
  ) {
    text += BOSSPACK_WATERMARK;
  } else if (
    data.sttProduct !== "BOSSPACK" &&
    data.sttProduct !== "SAMEDAY" &&
    data.sttIsCod &&
    !data.isDangerousGoods
  ) {
    text += NEW_COD_WATERMARK;
  } else if (
    data.sttProduct !== "BOSSPACK" &&
    data.sttProduct !== "SAMEDAY" &&
    data.sttIsCod &&
    data.isDangerousGoods
  ) {
    text += NEW_COD_WATERMARK;
    text += DG_WATERMARK;
  } else if (
    data.sttProduct !== "BOSSPACK" &&
    data.sttProduct !== "SAMEDAY" &&
    !data.sttIsCod &&
    data.isDangerousGoods
  ) {
    text += DG_WATERMARK;
  }

  // logo lion parcel
  text += `
    ^FX SECTION LOGO LP
    ^FO${x -
      5},${(yLeft += 18)}^GFA,2112,2112,33,,K0hYFC,J07iF,J0iGF8I01iGFC:I03iGFC:I03iGF8I07iGF8:I07iGF,I0iHF,I0gIFE7gWF,I0gIFC3gVFE,001gIF87gVFE,:001gIF07FF7gSFC,003gIF07FC09gRFC,003gIF07E001gRFC,003gIF07EI07gQF8,003OFC1RF0600203gQF8,007JF01IF00QF986J01gJFE07JF8,007IFE00FFE00QF183J043gIFC03JF,007IFE01FFE00QF082K03gIFC03JF,00JFE01IF01QF0C2K03gIF807JF,00JFC01IF87QF0C2J013gIF807IFE,00JFC01MF1JF87FF041J03IFE1JF1IFE3FFC7FE3FF807IFE,01JFC03FFC03F001F8I07F861J03FFI01FF8001I07C01E003F00JFE,01JF803FFC03CI0F8I03F831J03FEJ0FEI03I07001C001F00JFC,01JF803FFC038I078I01FC108J0FEJ07CI03I0C003I01F00JFC,03JF807FF807J07J01FE188J0FE008078I020018006J0E01JFC,03JF007FF806J03J01E60C4I07FC03E0703C06003I060180E01JF8,03JF007FF80403C03J01E38626107FC03E0607C0601E00FC07C0E01JF8,07JF00IF00C07E0201E01E1C318043FC07E060780603E01FC0FC0C01JF8,07IFE007FF0080FC0601E01E0E080021F807E040F80C03E03F8J0C03JF,07IFEJ0300807C0603C03F03840011F807C040F80C03C03F8J0C03JF,0JFEJ06018J0C03C03F80EI011F80F8040F00C07C0018I03C03JF,0JFEJ06018I01C03C03FC018I01FK0C0F00807E00181IF807IFE,0JFEJ0601CI03C03807FFL01FJ01C0101807E00180IF807IFE,1KFJ0403CI07807807F1FK01FJ03CI0180FFI0CI0F807IFE,1KFJ0C03F001F807807F03FC0013EJ07E020100FF800E001F00JFC,1KFC001C03F807F80F80FF8K013E0101FF0E0301FFE01F807F00JFC,3gJFC0060027E03gLFC,3gJFE03CI0FC03gLF8,3gLFE1009FC03gLF8,7gKF002563FC07gLF8,7gKF80C11FF807gLF,7gKFC3893gQF,7gLFC113gQF,7gLF8227gPFE,7gMFC6gQFE,7iFE,3iFE,3iFC,:1iFC,0iF8,:07hYF8,03hYF,01hYF,007hWFE,001hWF8,^FS\n`;

  // dg
  if (data.isDangerousGoods) {
    x +=
      360 + (specialProduct ? 0 : productLength[data.sttProductShort.length]);
    text += `
    ^FX SECTION DG
    ^FO${x},${(yLeft += 2)},1^GB90,60,40, B, 2^FS
    ^FT${x + 10},65,1^FR^A0N,42,60^FB90,1,0,C^FH
    ^FDDG^FS\n`;

    x = startX;
  }

  // product
  text += `
    ^FX SECTION PRODUCT
    ${
      specialProduct
        ? `^FO560,${yLeft},1^GB140,60,40, B, 2^FS^FT567,65,1^FR^A0N,42,50^FB140,1,0,C^FH`
        : `^FB560,1,0,R^FH^FT0,68^A0N,48^FH`
    }
    ^FD${data.sttProductShort}^FS\n`;

  // STT Number, barcode
  text += `
    ^FX SECTION STT Number & Barcode
    ^FT${x},${(yLeft += 110)}^A0N,36,53^FH^FD${data.sttNumber}^FS
    ^BY2,2,90^FT${x},230^BCN,,N,N^FD${data.sttNumber}^FS\n`;

  // ref
  text += `
    ^FX SECTION REF
    ^FT${x},${(yLeft += 130)}^A0N,16^FH^FDRef:^FS^FT${x +
    30},260^A0N,18,20^FH^FD${ellipsisString(data.sttExternalRefNumber, 35)}^FS
    ^FT${x},${(yLeft += 25)}^A0N,18,20^FH^FD${ellipsisString(
    data.sttClientName,
    40
  )}^FS\n`;

  // sender receiver
  x = startX;
  text += `
  ^FX SECTION SENDER-RECEIVER
  ^FT${x},${(yLeft += 45)}^A0N,18^FH^FD${translate(
    "receipt.shipper"
  )?.toUpperCase()}: ${ellipsisString(data.sttSenderName, 20)} ${
    data.sttSenderPhone
  }^FS
  ^FT${x},${(yLeft += 32)}^A0N,22^FH^FD${translate(
    "receipt.receiver"
  )?.toUpperCase()}: ${ellipsisString(
    data.sttRecipientName.toUpperCase(),
    20
  )},^FS
  ^FB520,4,5,L^FT${x},${(yLeft += 112)}^A0N,22^FH^FD${
    data.sttRecipientPhone
  } ${ellipsisString(data.sttRecipientAddress, 200)}^FS
  ^FB520,4,5,L^FT${x},${(yLeft += 150)}^A0N,24^FH^FD${
    data.sttDistrictDestination
  }^FS\n`;
  yLeft -= 40;

  // flag stt
  // KT
  x = 30;
  data.sttFlag.forEach((value: string) => {
    const length: { [x: number]: any } = {
      2: {
        bgWidth: 80,
        textWidth: 65
      },
      3: {
        bgWidth: 110,
        textWidth: 100
      }
    };

    x += length[value.length]?.bgWidth;
    const paddingText =
      value.length === 3 ? (["RMR", "RHQ", "RMB"].includes(value) ? 0 : 7) : 0;

    text += `
    ^FX SECTION FLAG STT - ${value}
    ^FO${x},${yLeft},1^GB${length[value.length]?.textWidth},45,50, B, 2^FS
    ^FT${x + paddingText},${(yLeft += 38)},1^FR^A0N,36,50^FB${
      length[value.length]?.textWidth
    },1,0,C^FH
    ^FD${value}^FS\n`;

    yLeft -= 38;
  });

  x = startX;

  // commodity
  text += `
    ^FX SECTION COMMODITY
    ^FB200,2,0,R^FH^FT350,${(yLeft += 40)}^A0N,18^FH
    ^FD${ellipsisString(
      data.language === "en" ? data.sttCommodityDescription : data.sttCommodity,
      30
    )}^FS\n`;

  if (data.sttVendorNumber) {
    // AWB vendor
    text += `
      ^FX SECTION AWB VENDOR Number & Barcode
      ^FT${x},${yLeft + 55}^A0N,28,26^FH^FD${data.sttVendorNumber}^FS
      ^BY1,3,60^FT${x},${yLeft + 125}^BCN,,N,Y,A^FD${
      data.sttVendorNumber
    }^FS\n`;
  }

  if (data.sttVendorName) {
    // vendor name
    text += `
      ^FX SECTION FLAG VENDOR
      ^FX SECTION NX
      ^FO560,${yLeft + 70},1^GB65,45,50, B, 2^FS
      ^FT560,${yLeft + 108},1^FR^A0N,36,50^FB65,1,0,C^FH
      ^FD${data.sttVendorPickupDetail}^FS\n`;
  }

  yLeft += 108;

  // origin destination
  x = 570;
  text += `
    ^FX SECTION VERTICAL LINE - DELIVERY
    ^FO${x},${yRight}^GB2,760,2^FS

    ^FX SECTION ORIGIN DESTINATION
    ^FT${(x += 15)},${(yRight += 60)}^A0N,32,60^FH^FD${
    data.sttOrigin3lc
  }^FS^FT${x},${(yRight += 65)}^A0N,64,100^FH^FD${data.sttDestination3lc}^FS

    ^FX SECTION HORIZONTAL LINE - ORIGIN DESTINATION
    ^FO${(x -= 15)},${(yRight += 20)}^GB300,2,2^FS\n`;

  // ursa code
  text += `
    ^FX SECTION URSA CODE
    ^FT${(x += 15)},${(yRight += 75)}^A0N,68,60^FH^FD${
    data.sttUrsaCode
  }^FS^FT${x},${(yRight += 30)}^A0N,18,20^FH
    ^FD${startCase(
      translate("receipt.createdAtV2")
    )}^FS^FT670,${yRight}^A0N,18^FH^FD: ${moment(data.sttDate).format(
    "DD/MM/YYYY"
  )}^FS
    ^FT${x},${(yRight += 30)}^A0N,18,20^FH
    ^FD${startCase(
      translate("receipt.estimation")
    )}^FS^FT670,${yRight}^A0N,18^FH^FD: ${moment(
    data.sttSla,
    "DD MMMM YYYY",
    "id"
  ).format("DD/MM/YYYY")}^FS

    ^FX SECTION HORIZONTAL LINE - URSA CODE
    ^FO0,${(yRight += 20)}^GB810,2,2^FS\n`;

  // weight, dimension
  const isBigpackVolume = data.sttChargeableWeight <= 10 && data.sttProduct.toUpperCase() === "BIGPACK";
  const isJumbopackvolume = data.sttChargeableWeight <= 30 && data.sttProduct.toUpperCase() === "JUMBOPACK";
  const CwValue = isBigpackVolume ? 'Min. 10 kg' : isJumbopackvolume ? 'Min. 30 kg' : `${formatDecimal(
    String(data.sttChargeableWeight)
  )} kg`
  const vwValue = data.sttDimensionWeight.replace(
    /\//g,
    "x"
  ) + 'cm'
  text += `
    ^FX SECTION WEIGHT
    ^FT${x},${(yRight += 65)}^A0N,60,50^FH^FD${isBigpackVolume || isJumbopackvolume ? '-' : formatDecimal(
    data.sttWeight?.split(" / ")[0]
  ).toLowerCase()}^FS
    ^FT${x},${(yRight += 40)}^A0N,24,20^FH^FD${isBigpackVolume || isJumbopackvolume ? '-' : vwValue}^FS
    ^FT${x},${(yRight += 30)}^A0N,20^FH^FDCW: ${CwValue}^FS

    ^FX SECTION HORIZONTAL LINE - WEIGHT DIMENSION
    ^FO${(x -= 15)},${(yRight += 15)}^GB300,2,2^FS\n`;

  // pieces
  text += `
  ^FX SECTION PIECES
  ^FT${(x += 15)},${(yRight += 90)}^A0N,96,90^FH^FD${data.sttPiece}^FS

  ^FX SECTION HORIZONTAL LINE - PIECES
  ^FO${(x -= 15)},${(yRight += 15)}^GB300,2,2^FS\n`;

  yLeft = 760;
  text += `
    ^FX SECTION HORIZONTAL LINE - DELIVERY
    ^FO0,${yLeft}^GB810,2,2^FS\n`;

  // amount
  let shipmentId = data.sttShipmentId ? data.sttShipmentId : "";
  let isPriceOfCOD = data.sttIsCod;
  let priceString = "-";
  let bKirimValue = "-";
  let bLainnyaValue = "-";
  let pajakPmString = "-";
  let showPajak = true;
  const pajakValue =
    Number(data.sttBmTaxRate) +
    Number(data.sttPpnTaxRate) +
    Number(data.sttPphTaxRate);
  if (
    data.isReverseJourney &&
    (shipmentId.startsWith(
      FlagsFeatureShipmentC1.feature_shipment_c1.getValue()
    ) ||
      shipmentId.match(/^AP|^AS/gi))
  ) {
    shipmentId = data.sttReverseJourneyShipmentId;
    isPriceOfCOD = data.sttReverseJourneyCodHandling;
  }

  if (data.isReverseJourney) {
    shipmentId = data.sttReverseJourneyShipmentId;
  }
  const isEstimationBound = estimationBound(
    shipmentId,
    data.bookingType,
    data.accountType,
    isPriceOfCOD
  );

  if (!isEstimationBound) {
    priceString = formatPrice(data.sttBookedByCountry, data.totalTariff || 0);

    const bKirimCalculation =
      data.sttShippingSurchargeRateAfterDiscount +
      data.sttPublishRateAfterDiscount +
      data.sttOriginDistrictRateAfterDiscount +
      data.sttDestinationDistrictRateAfterDiscount;

    bKirimValue = formatPrice(data.sttBookedByCountry, bKirimCalculation);

    const bLainnyaValueCalculation = data.sttIsPromo
      ? Number(data.sttCommoditySurchargeRateAfterDiscount) +
        Number(data.sttHeavyweightSurchargeRateAfterDiscount) +
        Number(data.sttDocumentSurchargeRateAfterDiscount) +
        (Number(data.sttInsuranceRate) || 0) +
        Number(data.sttCodFee) +
        Number(data.sttWoodpackingAfterRateDiscount)
      : Number(data.sttCommoditySurchargeRate) +
        Number(data.sttHeavyweightSurchargeRate) +
        Number(data.sttDocumentSurchargeRate) +
        (Number(data.sttInsuranceRate) || 0) +
        Number(data.sttCodFee) +
        Number(data.sttWoodpackingRate);

    bLainnyaValue = formatPrice(
      data.sttBookedByCountry,
      bLainnyaValueCalculation
    );

    pajakPmString = formatPrice(data.sttBookedByCountry, pajakValue);
    showPajak = pajakValue > 0;
  } else {
    if (pajakValue > 0) {
      pajakPmString = "-";
    } else {
      showPajak = false;
    }
  }

  if (data.sttIsCod) {
    priceString = data.sttCodAmount;

    text += `
    ^FX SECTION COD
    ^FT585,${(yRight += 55)}^A0N,44,60^FH^FDCOD^FS`;
  } else {
    text += `
    ^FX SECTION NON-COD
    ^FT585,${(yRight += 50)}^A0N,28^FH^FD${startCase(
      translate("receipt.totalCost")
    )}^FS`;
  }

  text += `
  ^FT585,${(yRight += 35)}^A0N,28,34^FH^FD${priceString}^FS
  ^FT585,${(yRight += 35)}^A0N,18,20^FH^FD${translate(
    "receipt.shippingCostV2"
  )}^FS^FT677,${yRight}^A0N,18^FH^FD: ${bKirimValue}^FS
  ^FT585,${(yRight += 30)}^A0N,18,20^FH^FD${translate(
    "receipt.othersV2"
  )}^FS^FT677,${yRight}^A0N,18^FH^FD: ${bLainnyaValue}^FS`;

  if (showPajak) {
    text += `
    ^FT585,${(yRight += 30)}^A0N,18,20^FH^FD${translate(
      "receipt.taxBm"
    )}^FS^FT677,${yRight}^A0N,18^FH^FD: ${pajakPmString}^FS`;
  } else {
    yRight += 30;
  }
  text += `\n`;

  // quote
  x = startX;
  text += `
    ^FX SECTION QUOTE
    ^FB725,3,5,C^FT${x},${(yLeft += 100)}^A0N,24^FH^FD${ellipsisString(
    data.campaignSttQuote.replace(/[“”]/g, '"').replace(/~/g, "-"),
    120
  )}^FS

    ^FX SECTION HORIZONTAL LINE - QUOTE
    ^FO0,${(yLeft -= 10)}^GB810,2,2^FS\n`;

  // ripper
  text += `
  ^FX SECTION RIPPER
  ^FO${(x += 5)},${(yLeft += 15)}^A0N,11^FH^FD${translate(
    "receipt.terms"
  )}^FS^FT615,1165,1^FS
  ^FO590,${yLeft}^A0N,11^FH^FD${translate("receipt.info")}^FS^FT615,1165,1^FS
  ^FO${(x -= 25)},${(yLeft += 5)}^GFA,63,63,3,,::0F,1D818,18C38,10C7,198E,0FDC,06F8,007,06E,0FCC,198E,10C7,18C38,1D818,0F,,::^FS
  ^FO0,${(yLeft += 10)}^GB7,2,2^FS
  ^FO10,${yLeft}^GB7,2,2^FS
  ^FO20,${yLeft}^GB7,2,2^FS
  ^FO30,${yLeft}^GB7,2,2^FS
  ^FO40,${yLeft}^GB7,2,2^FS
  ^FO50,${yLeft}^GB7,2,2^FS
  ^FO60,${yLeft}^GB7,2,2^FS
  ^FO70,${yLeft}^GB7,2,2^FS
  ^FO80,${yLeft}^GB7,2,2^FS
  ^FO90,${yLeft}^GB7,2,2^FS
  ^FO100,${yLeft}^GB7,2,2^FS
  ^FO110,${yLeft}^GB7,2,2^FS
  ^FO120,${yLeft}^GB7,2,2^FS
  ^FO130,${yLeft}^GB7,2,2^FS
  ^FO140,${yLeft}^GB7,2,2^FS
  ^FO150,${yLeft}^GB7,2,2^FS
  ^FO160,${yLeft}^GB7,2,2^FS
  ^FO170,${yLeft}^GB7,2,2^FS
  ^FO180,${yLeft}^GB7,2,2^FS
  ^FO190,${yLeft}^GB7,2,2^FS
  ^FO200,${yLeft}^GB7,2,2^FS
  ^FO210,${yLeft}^GB7,2,2^FS
  ^FO220,${yLeft}^GB7,2,2^FS
  ^FO230,${yLeft}^GB7,2,2^FS
  ^FO240,${yLeft}^GB7,2,2^FS
  ^FO250,${yLeft}^GB7,2,2^FS
  ^FO260,${yLeft}^GB7,2,2^FS
  ^FO270,${yLeft}^GB7,2,2^FS
  ^FO280,${yLeft}^GB7,2,2^FS
  ^FO290,${yLeft}^GB7,2,2^FS
  ^FO300,${yLeft}^GB7,2,2^FS
  ^FO310,${yLeft}^GB7,2,2^FS
  ^FO320,${yLeft}^GB7,2,2^FS
  ^FO330,${yLeft}^GB7,2,2^FS
  ^FO340,${yLeft}^GB7,2,2^FS
  ^FO350,${yLeft}^GB7,2,2^FS
  ^FO360,${yLeft}^GB7,2,2^FS
  ^FO370,${yLeft}^GB7,2,2^FS
  ^FO380,${yLeft}^GB7,2,2^FS
  ^FO390,${yLeft}^GB7,2,2^FS
  ^FO400,${yLeft}^GB7,2,2^FS
  ^FO410,${yLeft}^GB7,2,2^FS
  ^FO420,${yLeft}^GB7,2,2^FS
  ^FO430,${yLeft}^GB7,2,2^FS
  ^FO440,${yLeft}^GB7,2,2^FS
  ^FO450,${yLeft}^GB7,2,2^FS
  ^FO460,${yLeft}^GB7,2,2^FS
  ^FO470,${yLeft}^GB7,2,2^FS
  ^FO480,${yLeft}^GB7,2,2^FS
  ^FO490,${yLeft}^GB7,2,2^FS
  ^FO500,${yLeft}^GB7,2,2^FS
  ^FO510,${yLeft}^GB7,2,2^FS
  ^FO520,${yLeft}^GB7,2,2^FS
  ^FO530,${yLeft}^GB7,2,2^FS
  ^FO540,${yLeft}^GB7,2,2^FS
  ^FO550,${yLeft}^GB7,2,2^FS
  ^FO560,${yLeft}^GB7,2,2^FS
  ^FO570,${yLeft}^GB7,2,2^FS
  ^FO580,${yLeft}^GB7,2,2^FS
  ^FO590,${yLeft}^GB7,2,2^FS
  ^FO600,${yLeft}^GB7,2,2^FS
  ^FO610,${yLeft}^GB7,2,2^FS
  ^FO620,${yLeft}^GB7,2,2^FS
  ^FO630,${yLeft}^GB7,2,2^FS
  ^FO640,${yLeft}^GB7,2,2^FS
  ^FO650,${yLeft}^GB7,2,2^FS
  ^FO660,${yLeft}^GB7,2,2^FS
  ^FO670,${yLeft}^GB7,2,2^FS
  ^FO680,${yLeft}^GB7,2,2^FS
  ^FO690,${yLeft}^GB7,2,2^FS
  ^FO700,${yLeft}^GB7,2,2^FS
  ^FO710,${yLeft}^GB7,2,2^FS
  ^FO720,${yLeft}^GB7,2,2^FS
  ^FO730,${yLeft}^GB7,2,2^FS
  ^FO740,${yLeft}^GB7,2,2^FS
  ^FO750,${yLeft}^GB7,2,2^FS
  ^FO760,${yLeft}^GB7,2,2^FS
  ^FO770,${yLeft}^GB7,2,2^FS
  ^FO780,${yLeft}^GB7,2,2^FS
  ^FO790,${yLeft}^GB7,2,2^FS
  ^FO800,${yLeft}^GB7,2,2^FS
  ^FO810,${yLeft}^GB7,2,2^FS\n`;

  // SECTION RECIPIENT HEADER

  const ellipsisCommodityLanguage =
    data.language === "en" ? data.sttCommodityDescription : data.sttCommodity;

  const ellipsisCommodity =
    ellipsisCommodityLanguage.length > 30
      ? `${ellipsisCommodityLanguage.substring(0, 30)}...`
      : ellipsisCommodityLanguage;

  x = startX;
  text += `
  ^FX SECTION RECIPIENT HEADER
  ^FX LOGO TINY SECTION
  ^FO${x},${(yLeft += 20)}^GFA,480,480,16,,007gMFC,00gNFE,:01gNFE,01gNFC,01PFEWFC,03PFCWFC,03PFCFBUF8,03PF8C07TF8,07JF1KFC803TF8,07F83E1KF4800QF0FF,07F87E3JFEJ0QF0FF,0FF87E38380F2003C07C081830E1FF,0FF07C201807I03C03008104061FE,0FF0FC00100680078621J08021FE,1FF0F84710864013862310C38C03FE,1FF00842118610038E0310C30043FC,1FF0084021878003K01C08FC3FC,3FF008E0610F3803006021E080C7FC,3LFBJFI071NF7IFC,3RF8061RF8,7QFC13E1RF8,7RF03TF8,7RFDUF,3gNF,:1gMFE,0gMFE,07gLFC,^FS

  ^FX PRODUCT NAME TINY SECTION
  ^FO245,${yLeft}^GB100,30,30, B, 2^FS^FT245,${yLeft +
    22}^FR^A0N,18^FB100,1,0,C^FH^FD${data.sttProduct}^FS

  ^FX BARCODE SECTION
  ^FT${x},${(yLeft += 75)}^A0N,36,28^FH^FD${data.sttNumber}^FS
  ^BY1,3,50^FT${x},${(yLeft += 60)}^BCN,,N,N^FD${data.sttNumber}^FS\n`;

  // dg 2
  yLeft += 50;
  if (data.isDangerousGoods) {
    text += `
    ^FX SECTION DG 2
    ^FO${(x += 60)},${yLeft},1^GB60,40,40, B, 2^FS
    ^FT${(x += 20)},${(yLeft += 32)},1^FR^A0N,28,32^FB90,1,0,C^FH
    ^FDDG^FS\n`;

    yLeft -= 32;
    x -= 15;
  }

  text += `
  ^FX SECTION COMMODITY NAME
  ^FO${x},${yLeft + 10}^FB115,2,0,L,0^FD${ellipsisCommodity}^A0N,12^FS`;

  x = startX;
  if (
    data.sttBookedBy !== "internal" &&
    !(data.sttBookedForName === data.sttBookedBy && data.sttClientName)
  ) {
    text += `^FX SECTION POS NAME
    ^FO${x},${(yLeft += 65)}^FB180,2,0,L,0^FD${data.sttBookedBy}^A0N,15^FS\n`;
  } else {
    yLeft += 65;
  }

  // SECTION VIRAL
  x = 370;
  text += `
  ^FX SECTION VIRAL
  ^FO${x},${(yRight += 165)}^GB420,150,2,B,1^FS\n`;

  if (
    FlagsPermissionPrintReceipt.permission_campaign_viral_enable.isEnabled() &&
    isSTTCAOrRetail &&
    !data.isReverseJourney
  ) {
    const urlCampaign = `${process.env.VUE_APP_WEBSITE_LION_PARCEL}/ceocard`;
    text += `
    ^CF0,42
    ^FO${(x += 15)},${(yRight += 35)}^FDVIRAL^FS
    ^CF0,15
    ^FO${x},${(yRight += 45)}^FDKumpulkan kartu Densu^FS
    ^FO${x},${(yRight += 20)}^FD&menangkan hadiahnya!^FS
    ^FO${(x += 165)},${(yRight -= 85)}^GB118,120,2,B,1^FS
    ^FT${(x += 106)},${(yRight += 120)},1^BQN,2,3,H,7^FDQA,${urlCampaign}^FS
    ^FO${(x += 9)},${(yRight -= 120)}^GB118,119,60, B, 0^FS
    ^FO${(x += 9)},${(yRight += 35)}^FB100,2,0,C,0^A0N,32^FR^FD${translate(
      "receipt.scanHere"
    )?.toUpperCase()}^FS 
    `;
  } else {
    const combineQRTextForRegular = `https://lionparcel.com/track/stt?q=${data.sttNumber}`;
    const combineQRTextForCourirRecomendation = JSON.stringify({
      stt_no: data.sttNumber,
      partner_name: data.sttBookedForName,
      booking_id: data.otherShipperTicketCode
    });
    let qrcodeValue = "";
    if (isCourierRecommendation(data.sttExternalRefNumber)) {
      qrcodeValue = combineQRTextForCourirRecomendation;
    } else {
      qrcodeValue = combineQRTextForRegular;
    }
    text += `
    ^CF0,17
    ^FO${(x += 15)},${(yRight += 45)}^FD${translate(
      "receipt.infoTrackingPrn.info1"
    )}^FS
    ^FO${x},${(yRight += 25)}^FD${translate("receipt.infoTrackingPrn.info2")}^FS
    ^FO${x},${(yRight += 25)}^FD${translate("receipt.infoTrackingPrn.info3")}^FS
    ^FO${(x += 165)},${(yRight -= 80)}^GB118,120,2,B,1^FS
    ^FT${(x += 108)},${(yRight += 130)},1^BQN,2,3,H,7^FDQA,${qrcodeValue}^FS
    ^FO${(x += 9)},${(yRight -= 130)}^GB118,119,60, B, 0^FS
    ^FO${(x += 9)},${(yRight += 35)}^FB100,2,0,C,0^A0N,32^FR^FD${translate(
      "receipt.scanHere"
    )?.toUpperCase()}^FS 
    `;
  }

  // Recipient Section - Breakdown Tariff
  const breakDownTariffX = 225;

  const tariffTitle = data.sttIsCod
    ? "COD"
    : startCase(translate("receipt.totalCost"));
  const leftTarifValue = data.sttIsCod
    ? breakDownTariffX + 55
    : breakDownTariffX + 92;

  if (!data.sttIsCod) {
    priceString = `: ${priceString}`;
  }

  text += `
    ^FX SECTION VERTICAL LINE - DELIVERY
    ^FO${breakDownTariffX + 215},${(yLeft -= 80)}^GB2,120,2,B,1^FS

    ^FO${breakDownTariffX},${yLeft}^GB570,120,2,B,1^FS
    ^FO${breakDownTariffX + 15},${(yLeft += 15)}^FD${tariffTitle}^A0N,17^FS
    ^CF0,16
    ^FO${leftTarifValue},${yLeft}^FD${priceString}^FS
    ^FO${breakDownTariffX + 15},${(yLeft += 20)}^FD${translate(
    "receipt.shippingCostV2"
  )}^FS
    ^FO${breakDownTariffX + 92},${yLeft}^FD: ${bKirimValue}^FS
    ^FO${breakDownTariffX + 15},${(yLeft += 20)}^FD${translate(
    "receipt.othersV2"
  )}^FS
    ^FO${breakDownTariffX + 92},${yLeft}^FD: ${bLainnyaValue}^FS
  `;

  if (!isEstimationBound && data.sttIsCod) {
    text += `
      ^FO240,${(yLeft += 20)}^FD${translate("receipt.returnFeeV2")}^FS
      ^FO317,${yLeft}^FD: ${formatPrice(
      data.sttBookedByCountry,
      data.sttReturnFee
    )}^FS
    `;
  }

  if (showPajak) {
    text += `
      ^FO240,${(yLeft += 20)}^FD${translate("receipt.taxBm")}^FS
      ^FO317,${yLeft}^FD: ${pajakPmString}^FS
    `;
  }

  // Recipient Section - Breakdown Recipient
  const breakDownRecipientX = 455;

  const sttRecipient =
    data.sttRecipientName.length > 20
      ? `${data.sttRecipientName.substring(0, 20)}...`
      : data.sttRecipientName;

  const districtString =
    data.sttDistrictDestination.length > 27
      ? `${data.sttDistrictDestination.substring(0, 27)}...`
      : data.sttDistrictDestination;

  const newSttCreatedAtDate = new Date(data.sttCreatedAt);
  const sttCreatedAt = new Intl.DateTimeFormat("en-GB").format(
    newSttCreatedAtDate
  );

  const estimateSla = moment(data.estimateSla, "DD MMM YYYY").format(
    "DD/MM/YYYY"
  );

  text += `
    ^FO${breakDownRecipientX},${(yRight += 135)}^FD${startCase(
    translate("receipt.receiver")
  )}^FS
    ^FO${breakDownRecipientX + 90},${yRight}^FD: ${sttRecipient}^FS

    ^FO${breakDownRecipientX},${(yRight += 20)}^FD${startCase(
    translate("receipt.destination")
  )}^FS
    ^FO${breakDownRecipientX + 90},${yRight}^FD: ${districtString}^FS

    ^FO${breakDownRecipientX},${(yRight += 20)}^FD${startCase(
    translate("receipt.createdAtV2")
  )}^FS
    ^FO${breakDownRecipientX + 90},${yRight}^FD: ${sttCreatedAt}^FS

    ^FO${breakDownRecipientX},${(yRight += 20)}^FD${startCase(
    translate("receipt.estimation")
  )}^FS
    ^FO${breakDownRecipientX + 90},${yRight}^FD: ${estimateSla}^FS
  `;

  if (data.isHubToHub) {
    text += `
      ^FO${breakDownRecipientX},${(yRight += 20)}^FDInstruksi^FS
      ^FO${breakDownRecipientX + 90},${yRight}^FD: Paket diambil di agen^FS
    `;
  }

  // SECTION FOOTER
  x = startX;
  text += `
  ^FX SECTION FOOTER
  ^FO${x},1200^A0N,11^FH^FD${translate("receipt.terms")}^FS^FT615,1165,1^FS
  ^FO590,1200^A0N,11^FH^FD${translate("receipt.info")}^FS^FT615,1165,1^FS\n`;

  // end
  text += `^XZ\n\n\n`;

  return text;
};
