import { ellipsisString, uuid } from "@/app/infrastructures/misc/Utils";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import jsPDF from "jspdf";

export const createRoundRect = (doc: jsPDF | any, text: string, x: number, y: number) => {
    doc.setFontSize(7.5);
    doc.setFillColor("#333333");
    doc.setDrawColor("#333333");
    let res = 0;
   switch(text){
       case "JUMBOPACK":
       case "INTERPACK": 
        res = text.length - 7;
        break;
       case "BOSSPACK":
       case "JAGOPACK":
        res = text.length - 6;
        break;
       case "OTOPACK150":
       case "OTOPACK250":
        res = text.length - 7.9; 
        break;
       default:  
        res = text.length - 5;
        break;
    }
    

    const length = res;
    doc.roundedRect(x, y, doc.getTextDimensions(text).w + length, doc.getTextDimensions(text).h + 1.8, 1, 1, "F");

    doc.setTextColor("#FFFFFF");
    doc.setFont("Montserrat", "bold");
    doc.text(text, x + 1, y + 3.25);
    return {
        w: doc.getTextDimensions(text).w + length,
        h: doc.getTextDimensions(text).h + 1.8
    };
};

const getPackAbbreviation = (fullName: string) => {
    const abbreviations: any = {
        BOSSPACK: "BOSSPACK",
        REGPACK: "REGPACK",
        JAGOPACK: "JAGOPACK",
        ONEPACK: "ONEPACK",
        INTERPACK: "INTERPACK",
        BIGPACK: "BIGPACK",
        JUMBOPACK: "JUMBOPACK",
        SAMEDAY: "SAMEDAY",
        OTOPACK150: "OTOPACK150",
        OTOPACK250: "OTOPACK250",
    };

    return abbreviations[fullName] || fullName; // Return the abbreviation or the original fullName if not found
};


export const RecipientHeaderSection = async (
    params: {
        doc: jsPDF;
        bookingType: string;
        accountType: string;
        data: PrintDataEntitas;
        pieceData: any;
        barcode: string;
        vendorRefCode: string;
        barcodeValue: string;
        vendorRefCodeValue: string;
        logo: string;
        shipmentId: string;
        startX: number;
        startY: number;
    },
    logoAspectRatio: number,
    language = "id"
) => {
    const newLogo = await import(
        "@/app/ui/assets/images/new-logo-lion-parcel-black.png"
    );
    params.doc.addImage(
        newLogo.default,
        "PNG",
        params.startX + 5,
        params.startY + 108,
        16,
        logoAspectRatio * 16,
        "logo",
        "FAST",
        0
    );
    createRoundRect(params.doc, getPackAbbreviation(params.data.sttProductType), params.startX + (params.data.sttProductType === "JUMBOPACK" || params.data.sttProductType.includes('OTOPACK') ? 27 : 29), params.startY + 108);


    params.doc.setFont("Montserrat", "bold");
    params.doc.setFontSize(11);
    params.doc.setTextColor("#333333");
    params.doc.text(params.barcodeValue, params.startX + 5.5, params.startY + 116.5);
    params.doc.addImage(
        params.barcode,
        "PNG",
        params.startX + 6,
        params.startY + 117,
        41,
        8,
        uuid(),
        "FAST",
        0
    );

    // DG SECTION
    const sttCommodityNamelanguage =
    language === "en"
      ? params.data.sttCommodityDescription
      : params.data.sttCommodityName;

    if (params.data.isDangerousGoods) {
        params.doc.setLineWidth(0.3);
        params.doc.setFillColor("#333333");
        params.doc.setDrawColor("#333333");
        params.doc.roundedRect(params.startX + 5.2, params.startY + 130, params.doc.getTextDimensions("DG").w + 2, params.doc.getTextDimensions("DG").h + 2.5, 0.5, 0.5, "FD");
        params.doc.setFont("Montserrat", "bold");
        params.doc.setTextColor("#FFFFFF");
        params.doc.setFontSize(10);
        params.doc.text("DG", params.startX + 6.5, params.startY + 134.5);
        params.doc.setFont("Montserrat", "normal");
        params.doc.setTextColor("#333333");
        params.doc.setFontSize(4.5);
        params.doc.text(params.doc.splitTextToSize(`DG-${ellipsisString(sttCommodityNamelanguage, 30)}`, 15), params.startX + 15, params.startY + 133);
    } else {
        params.doc.setFont("Montserrat", "normal");
        params.doc.setTextColor("#333333");
        params.doc.setFontSize(4.5);
        params.doc.text(params.doc.splitTextToSize(`${ellipsisString(sttCommodityNamelanguage, 30)}`, 20), params.startX + 5.5, params.startY + 133);
    }

    if (params.data.sttBookedName.toLowerCase() === 'internal') return;
    if (params.data.sttBookedName === params.data.sttBookedForName && params.data.sttClientName) return;
    params.doc.setFont("Montserrat", "bold");
    params.doc.setTextColor("#333333");
    params.doc.setFontSize(4.5);
    params.doc.text(params.doc.splitTextToSize(`${ellipsisString(params.data.sttBookedName, 30)}`, 25), params.startX + 5.2, params.startY + (params.data.isDangerousGoods ? 141 : 140));
}