import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { Pagination } from "@/domain/entities/Pagination";
import {
  BulkBookingAddForm,
  BulkBookingEntities,
  BulkBookingResiEntities,
  BulkBookingSuccess,
  ClientBookingEntities,
  ClientBookingTemplateDetail
} from "@/domain/entities/BulkBooking";
import {
  BulkBookingApiRequest,
  ClientBookingListParams
} from "@/data/payload/api/BulkBookingApiRequest";
import { container } from "tsyringe";
import { BulkBookingPresenter } from "../presenters/BulkBookingPresenter";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { BookingController } from "./BookingController";
import { AccountController } from "./AccountController";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import { MainAppController } from "@/app/ui/controllers/MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";
import {
  RequestBulkBooking,
  EditClientTemplateConfiguration,
  CreateClientTemplateConfiguration
} from "@/data/payload/api/BulkApiRequest";

export interface BulkBookingState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
  isModalSuccess: boolean;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "bulk-booking"
})
class BulkBookingStore extends VuexModule implements BulkBookingState {
  public IsOpenUploadBulkBooking = false;
  public isLoading = false;
  public IsOpenReUploadBulkBooking = false;
  public openModalSuccess = false;
  public clientName = "";
  public isError = false;
  public errorCause = "";
  public pagination = {
    page: 1,
    limit: 20
  };
  public bulkBookingList = new BulkBookingEntities(
    new Pagination(this.pagination.page, this.pagination.limit),
    []
  );
  public isConfirm = false;
  public errorDownload = false;
  public isFirstRequest = true;
  public resiListData: Array<PrintDataEntitas> = [];
  public status = "";
  public form = new BulkBookingAddForm();
  public isClearFile = false;
  public detailClientBookingData = new ClientBookingTemplateDetail();
  public clientBookingList = new ClientBookingEntities(
    new Pagination(this.pagination.page, this.pagination.limit),
    []
  );
  public isModalSuccess = false;

  @Action
  getBulkBookingList(params: RequestBulkBooking) {
    this.setIsLoading(true);
    const presenter = container.resolve(BulkBookingPresenter);
    presenter
      .getListBulkBooking(params)
      .then((res: BulkBookingEntities) => {
        this.setBulkBookingData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => this.setIsLoading(false));
  }

  @Action
  getListClientBooking(params: ClientBookingListParams) {
    this.setIsLoading(true);
    const presenter = container.resolve(BulkBookingPresenter);
    presenter
      .getListClientBookingTemplate(params)
      .then((res: ClientBookingEntities) => {
        this.setClientBookingData(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => this.setIsLoading(false));
  }

  @Action
  uploadBulkBooking(payload: {
    activityName: string;
    archiveFile: any;
    posClientId: number;
    posClientType: string;
    templateFormat: string;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BulkBookingPresenter);
    return presenter
      .uploadBulkBooking(
        new BulkBookingApiRequest(
          payload.activityName,
          payload.archiveFile,
          payload.posClientId,
          payload.posClientType,
          payload.templateFormat
        )
      )
      .then((res: ResponsePayload) => {
        return res;
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Unggah Berkas Gagal !", () =>
            this.uploadBulkBooking(payload)
          )
        );
        return new ResponsePayload();
      })
      .finally(() => {
        MainAppController.closeLoading();
        this.setStatusClearFile(true);
      });
  }

  @Action
  public async getBulkBookingResi(params: { id: number }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BulkBookingPresenter);
    return presenter
      .getListBulkBookingResi(params.id)
      .then(async (res: BulkBookingResiEntities) => {
        const list: Array<any> = [];
        let index = 0;
        for (const item of res.data) {
          const resi = await BookingController.generatePrintThermal({
            stt: item.sttId,
            accountType:
              AccountController.accountData.account_type === "partner"
                ? AccountController.accountData.account_type_detail.type
                : AccountController.accountData.account_type
          });

          list.push(resi);
          if (index === res.data.length - 1) {
            this.setResiListData(list);
          }

          index++;
        }

        return true;
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Cetak Resi Gagal !", () =>
            this.getBulkBookingResi(params)
          )
        );
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public fetchBulkBookingList(filter: {
    search: string;
    createdType: string;
    createdId: number;
    status: string;
  }) {
    this.setFirstRequest(false);
    this.getBulkBookingList(
      new RequestBulkBooking({
        archiveType: "stt",
        page: this.bulkBookingList.pagination.page,
        limit: this.bulkBookingList.pagination.limit,
        search: filter.search,
        status: filter.status,
        createdType: filter.createdType,
        createdId: filter.createdId,
        isTotalData: true
      })
    );
  }

  @Action
  public fetchClientBookingList(filter: { search: string }) {
    this.setFirstRequest(false);
    this.getListClientBooking(
      new ClientBookingListParams({
        page: this.clientBookingList.pagination.page,
        limit: this.clientBookingList.pagination.limit,
        search: filter.search,
        isTotalData: true
      })
    );
  }

  @Action
  public getSuccessListBooked(id: number) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BulkBookingPresenter);
    return presenter
      .getSuccessListBooked(id)
      .then((res: BulkBookingSuccess) => res)
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Unduh Gagal !", () =>
            this.getSuccessListBooked(id)
          )
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public getDetailClientBooking(id: number) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(BulkBookingPresenter);
    return presenter
      .getDetailClientBookingTemplate(id)
      .then((res: ClientBookingTemplateDetail) => {
        this.setDetailClientBooking(res);
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public downloadFailedFile(id: number) {
    const presenter = container.resolve(BulkBookingPresenter);
    presenter.downloadFailedFile(id);
  }

  @Action
  public selectStatus(value: string) {
    this.selectType(value);
  }

  @Action
  public editClientTemplateConfiguration(params: {
    id: number;
    headerMapping: any;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();

    const presenter = container.resolve(BulkBookingPresenter);
    const payload = new EditClientTemplateConfiguration(params.headerMapping);
    return presenter
      .editClientTemplateConfiguration(params.id, payload)
      .then(() => {
        this.setModalSuccess(true);
        return true;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembuatan Gagal !", () =>
            this.editClientTemplateConfiguration(params)
          )
        );
        this.setModalSuccess(false);
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public createClientTemplateConfiguration(params: {
    accountRefId: number;
    accountRefType: string;
    headerMapping: any;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();

    const presenter = container.resolve(BulkBookingPresenter);
    const payload = new CreateClientTemplateConfiguration(
      params.accountRefId,
      params.accountRefType,
      params.headerMapping
    );
    return presenter
      .createClientTemplateConfiguration(payload)
      .then(() => {
        this.setModalSuccess(true);
        return true;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(err, "Pembuatan Gagal !", () =>
            this.createClientTemplateConfiguration(params)
          )
        );
        this.setModalSuccess(false);
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Mutation
  public async selectType(value: string) {
    this.status = value;
  }

  @Mutation
  public setErrorDownload(val: boolean) {
    this.errorDownload = val;
  }

  @Mutation
  private setBulkBookingData(data: BulkBookingEntities) {
    this.bulkBookingList = data;
  }

  @Mutation
  private setClientBookingData(data: ClientBookingEntities) {
    this.clientBookingList = data;
  }

  @Mutation
  public setResiListData(data: PrintDataEntitas[]) {
    this.resiListData = data;
  }

  @Mutation
  public setConfirm(value: boolean) {
    this.isConfirm = value;
  }

  @Mutation
  public setIsLoading(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  public setIsOpenUploadBulkBooking(value: boolean) {
    this.IsOpenUploadBulkBooking = value;
  }

  @Mutation
  public setIsOpenReUploadBulkBooking(value: boolean) {
    this.IsOpenReUploadBulkBooking = value;
  }

  @Mutation
  public setDetailClientBooking(val: ClientBookingTemplateDetail) {
    this.detailClientBookingData = val;
  }

  @Mutation
  public setOpenModalSuccess(val: boolean) {
    this.openModalSuccess = val;
  }

  @Mutation
  public setClientName(val: string) {
    this.clientName = val;
  }

  @Mutation
  public setError(value: boolean) {
    this.isError = value;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setStatusClearFile(val: boolean) {
    this.isClearFile = val;
  }

  @Mutation
  public setFirstRequest(value: boolean) {
    this.isFirstRequest = value;
  }

  @Mutation
  public setFormAddBulkBooking(data: BulkBookingAddForm) {
    this.form = data;
  }

  @Mutation
  public setFormatTemplate(val: string) {
    this.form.formatTemplate = val;
  }

  @Mutation
  public setModalSuccess(status: boolean) {
    this.isModalSuccess = status;
  }
}

export const BulkBookingController = getModule(BulkBookingStore);
