import formatDecimal2Digits from "@/app/infrastructures/misc/common-library/formatInputDecimalOnly2Digits";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import jsPDF from "jspdf";

export const weights = (
  params: {
    pieceData: any;
    doc: jsPDF | any;
    startX: number;
    startY: number;
    data: PrintDataEntitas;
  },
  coor: {
    startX: number;
    startY: number;
  },
  isBigpackVolume: boolean,
  isJumbopackvolume: boolean
) => {
  // GROSSWEIGHT
  params.doc.setFont("Montserrat", "bold");
  params.doc.setTextColor("#333333");
  params.doc.setFontSize(15);
  const GW = params.pieceData.stt_piece_gross_weight
    ? `${formatDecimal2Digits(
        String(params.pieceData.stt_piece_gross_weight)
      )} kg`
    : "";
  params.doc.text( isBigpackVolume || isJumbopackvolume ? "-" : GW, coor.startX + 78, coor.startY + 47);

  // VOLUMEWEIGHT
  params.doc.setFont("Montserrat", "bold");
  params.doc.setTextColor("#333333");
  params.doc.setFontSize(8);
  const VW = `${Math.round(params.pieceData.stt_piece_length)}x${Math.round(
    params.pieceData.stt_piece_width
  )}x${Math.round(params.pieceData.stt_piece_height)} cm`;
  params.doc.text(isBigpackVolume || isJumbopackvolume ? '-' : VW, coor.startX + 78, coor.startY + 52);

  // CW
  params.doc.setFont("Montserrat", "bold");
  params.doc.setTextColor("#333333");
  params.doc.setFontSize(7);
  params.doc.text("CW: ", coor.startX + 78, coor.startY + 55.5);
  const CwValue = isBigpackVolume ? 'Min. 10 kg' : isJumbopackvolume ? 'Min. 30 kg' : `${params.data.sttChargeableWeight} kg`
  params.doc.text(
    CwValue,
    coor.startX + 84,
    coor.startY + 55.5
  );

  // LINE HORIZONTAL
  params.doc.setLineWidth(0.4);
  params.doc.line(
    coor.startX + 77,
    coor.startY + 58,
    coor.startX + 105,
    coor.startY + 58
  );
};