/* eslint-disable @typescript-eslint/camelcase */
import { jsPDF } from "jspdf";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import { layoutSection } from "../layout";

const generatePDF = async (accountType: string, data: PrintDataEntitas[]) => {
  const importLogo = await import(
    "@/app/ui/assets/images/logo-lion-parcel-black.png"
  );
  const logo = importLogo.default;
  const doc: any = new jsPDF("p", "mm", "a4", true);
  const resp = data;
  let newArr: any = [];

  function groupByPage(arr: PrintDataEntitas[], size: number) {
    newArr = Array.from({ length: Math.ceil(arr.length / size) }, (_v, i) =>
      arr.slice(i * size, i * size + size)
    );
  }

  groupByPage(resp, 4);
  for (let i = 0; i < newArr.length; i++) {
    for (let j = 0; j < newArr[i].length; j++) {
      const item = newArr[i][j];
      const language = item.sttProductType === "INTERPACK" ? "en" : "id";
      const bookingType = () => {
        if (item.sttShipmentId) {
          return "shipment";
        } else if (!item.sttShipmentId && !item.sttClientId) {
          return "manual";
        } else {
          return "client";
        }
      };
      if (j === 0) {
        /*------------------------------------------------------
        ---------------------- SECTION 1 -----------------------
        ------------------------------------------------------ */
        await layoutSection(
          {
            doc,
            bookingType: bookingType(),
            accountType,
            data: item,
            pieceData: item,
            barcode: item.barcode,
            vendorRefCode: item.vendorRefCode,
            barcodeValue: item.sttNo,
            vendorRefCodeValue: item.sttVendorRefferenceNo,
            logo,
            shipmentId: item.sttShipmentId,
            startX: 0,
            startY: 0
          },
          language
        );
      } else if (j === 1) {
        /*------------------------------------------------------
        ---------------------- SECTION 2 -----------------------
        ------------------------------------------------------ */
        await layoutSection(
          {
            doc,
            bookingType: bookingType(),
            accountType,
            data: item,
            pieceData: item,
            barcode: item.barcode,
            vendorRefCode: item.vendorRefCode,
            barcodeValue: item.sttNo,
            vendorRefCodeValue: item.sttVendorRefferenceNo,
            logo,
            shipmentId: item.sttShipmentId,
            startX: 104,
            startY: 0
          },
          language
        );
      } else if (j === 2) {
        /*------------------------------------------------------
        ---------------------- SECTION 3 -----------------------
        ------------------------------------------------------ */
        await layoutSection(
          {
            doc,
            bookingType: bookingType(),
            accountType,
            data: item,
            pieceData: item,
            barcode: item.barcode,
            vendorRefCode: item.vendorRefCode,
            barcodeValue: item.sttNo,
            vendorRefCodeValue: item.sttVendorRefferenceNo,
            logo,
            shipmentId: item.sttShipmentId,
            startX: 0,
            startY: 147
          },
          language
        );
      } else {
        /*------------------------------------------------------
        ---------------------- SECTION 4 -----------------------
        ------------------------------------------------------ */
        await layoutSection(
          {
            doc,
            bookingType: bookingType(),
            accountType,
            data: item,
            pieceData: item,
            barcode: item.barcode,
            vendorRefCode: item.vendorRefCode,
            barcodeValue: item.sttNo,
            vendorRefCodeValue: item.sttVendorRefferenceNo,
            logo,
            shipmentId: item.sttShipmentId,
            startX: 104,
            startY: 147
          },
          language
        );
        if (newArr[i + 1] !== undefined) {
          doc.addPage("a4", "p");
        }
      }
    }
  }

  doc.setProperties({
    title: `bulk-print-resi.pdf`
  });
  doc.autoPrint();
  window.open(doc.output("bloburl"), "_blank");
};

export default generatePDF;
