import { oldReceipt } from "./old-receipt";
import { newReceipt } from "./new-receipt";
import { FlagsPermissionPrintReceipt } from "@/feature-flags/flags-permission-print-receipt";
import { PrintDataEntitas } from "@/domain/entities/Booking";

export const layoutSection = async (
  params: {
      doc: any;
      bookingType: string;
      accountType: string;
      data: PrintDataEntitas;
      pieceData: any;
      barcode: string;
      vendorRefCode: string;
      barcodeValue: string;
      vendorRefCodeValue: string;
      logo: string;
      shipmentId: string;
      startX: number;
      startY: number;
    },
    language = "id"
  ) => {
  if (FlagsPermissionPrintReceipt.receipt_version.getValue() === "v1") {
    await oldReceipt(params, language);
  } else {
    await newReceipt(params, language);
  }
}