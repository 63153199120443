import QRCode from "qrcode";
import { prefixAlgoEstimationShow } from "@/app/infrastructures/misc/Utils";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import jsPDF from "jspdf";
import JsBarcode from "jsbarcode";

export const productRect = (product: string) => {
  const type: any = {
    REGPACK: {
      rect: 18
    },
    LANDPACK: {
      rect: 20
    },
    ONEPACK: {
      rect: 18
    },
    INTERPACK: {
      rect: 20
    },
    DOCUPACK: {
      rect: 20
    },
    MIXPACK: {
      rect: 17
    },
    SDPACK: {
      rect: 16
    },
    BIGPACK: {
      rect: 17
    },
    DORPACK: {
      rect: 18
    },
    JAGOPACK: {
      rect: 19
    },
    "": {
      rect: 19
    }
  };

  const checkProductTypeExist = product in type;
  if (checkProductTypeExist) {
    return type[product].rect;
  } else {
    return type[""].rect;
  }
};

export const productRectColor = (product: string, isThermalPdf = false) => {
  const type: any = {
    REGPACK: {
      color: "#4185F7"
    },
    LANDPACK: {
      color: "#36B37E"
    },
    ONEPACK: {
      color: "#8E78DB"
    },
    INTERPACK: {
      color: "#E13E49"
    },
    DOCUPACK: {
      color: "#9B581F"
    },
    MIXPACK: {
      color: "#4D4D4D"
    },
    SDPACK: {
      color: "#4D4D4D"
    },
    BIGPACK: {
      color: "#ED6240"
    },
    DORPACK: {
      color: "#4D4D4D"
    },
    JAGOPACK: {
      color: "#36B37E"
    },
    "": {
      color: "#4D4D4D"
    }
  };

  const checkProductTypeExist = product in type;
  if (checkProductTypeExist && !isThermalPdf) {
    return type[product].color;
  } else {
    return type[""].color;
  }
};

export const sentDate = (month: string) => {
  const monthLabel: any = {
    Januari: {
      sectOneLeft: 70,
      sectOneLeftDoc: 74,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176
    },
    Februari: {
      sectOneLeft: 70,
      sectOneLeftDoc: 74,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176
    },
    Maret: {
      sectOneLeft: 73,
      sectOneLeftDoc: 77,
      sectTwoLeft: 179,
      sectThreeLeft: 77,
      sectFourLeft: 176
    },
    April: {
      sectOneLeft: 73,
      sectOneLeftDoc: 77,
      sectTwoLeft: 179,
      sectThreeLeft: 77,
      sectFourLeft: 176
    },
    Mei: {
      sectOneLeft: 73,
      sectOneLeftDoc: 77,
      sectTwoLeft: 179,
      sectThreeLeft: 77,
      sectFourLeft: 176
    },
    Juni: {
      sectOneLeft: 73,
      sectOneLeftDoc: 77,
      sectTwoLeft: 179,
      sectThreeLeft: 77,
      sectFourLeft: 176
    },
    Juli: {
      sectOneLeft: 73,
      sectOneLeftDoc: 77,
      sectTwoLeft: 179,
      sectThreeLeft: 78,
      sectFourLeft: 176
    },
    Agustus: {
      sectOneLeft: 70,
      sectOneLeftDoc: 40,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176
    },
    September: {
      sectOneLeft: 50,
      sectOneLeftDoc: 40,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176
    },
    Oktober: {
      sectOneLeft: 70,
      sectOneLeftDoc: 74,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176
    },
    November: {
      sectOneLeft: 70,
      sectOneLeftDoc: 74,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176
    },
    Desember: {
      sectOneLeft: 70,
      sectOneLeftDoc: 74,
      sectTwoLeft: 176,
      sectThreeLeft: 74,
      sectFourLeft: 176
    }
  };

  return monthLabel[month];
};

export const senderSection = (senderAddress: string) => {
  const senderDetail = {
    maxLengthAddress: 70,
    sectOneAndTwo: {
      ifAddressNormal: {
        heightReceiverLabel: 59,
        heightReceiverAddress: 63
      },
      ifAddressOver: {
        heightReceiverLabel: 58,
        heightReceiverAddress: 62
      }
    },

    sectThreeAndFour: {
      ifAddressNormal: {
        heightReceiverLabel: 204,
        heightReceiverAddress: 208
      },
      ifAddressOver: {
        heightReceiverLabel: 203,
        heightReceiverAddress: 207
      }
    }
  };

  let heightReceiverLabelSect1And2 =
    senderDetail.sectOneAndTwo.ifAddressNormal.heightReceiverLabel;
  let heightReceiverAddressSect1And2 =
    senderDetail.sectOneAndTwo.ifAddressNormal.heightReceiverAddress;
  let heightReceiverLabelSect3And4 =
    senderDetail.sectThreeAndFour.ifAddressNormal.heightReceiverLabel;
  let heightReceiverAddressSect3And4 =
    senderDetail.sectThreeAndFour.ifAddressNormal.heightReceiverAddress;
  if (senderAddress.length > senderDetail.maxLengthAddress) {
    senderAddress = `${senderAddress.substr(0, 150)}...`;
    heightReceiverLabelSect1And2 =
      senderDetail.sectOneAndTwo.ifAddressOver.heightReceiverLabel;
    heightReceiverAddressSect1And2 =
      senderDetail.sectOneAndTwo.ifAddressOver.heightReceiverAddress;
    heightReceiverLabelSect3And4 =
      senderDetail.sectThreeAndFour.ifAddressOver.heightReceiverLabel;
    heightReceiverAddressSect3And4 =
      senderDetail.sectThreeAndFour.ifAddressOver.heightReceiverAddress;
  }

  return {
    senderAddress: senderAddress,
    heightReceiverLabelSect1And2: heightReceiverLabelSect1And2,
    heightReceiverAddressSect1And2: heightReceiverAddressSect1And2,
    heightReceiverLabelSect3And4: heightReceiverLabelSect3And4,
    heightReceiverAddressSect3And4: heightReceiverAddressSect3And4
  };
};

export const receiverSection = (receiverAddress: string) => {
  const receiverDetail = {
    maxLengthAddress: 450
  };

  if (receiverAddress.length > receiverDetail.maxLengthAddress) {
    receiverAddress = `${receiverAddress.substr(0, 450)}...`;
  }

  return {
    receiverAddress: receiverAddress
  };
};

export const externalRefSection = (externalRef: string) => {
  const externalDetail = {
    maxLengthAddress: 15
  };

  if (externalRef.length > externalDetail.maxLengthAddress) {
    externalRef = `${externalRef.substr(0, 15)}...`;
  }

  return {
    externalRef: externalRef
  };
};

export const clientLabelSection = (clientValue: string) => {
  const clientLabelDetail = {
    sectOne: {
      normal: {
        x: 96,
        y: 99
      },
      over: {
        x: 95,
        y: 99
      }
    },
    sectTwo: {
      normal: {
        x: 196.5,
        y: 99
      },
      over: {
        x: 196,
        y: 99
      }
    },
    sectThree: {
      normal: {
        x: 96,
        y: 243
      },
      over: {
        x: 95,
        y: 243
      }
    },
    sectFour: {
      normal: {
        x: 196.5,
        y: 243
      },
      over: {
        x: 196,
        y: 243
      }
    },
    sectThermal: {
      normal: {
        x: 92.5,
        y: 99
      },
      over: {
        x: 91.5,
        y: 99
      }
    }
  };

  let clientValueCoorSect1 = clientLabelDetail.sectOne.normal;
  let clientValueCoorSect2 = clientLabelDetail.sectTwo.normal;
  let clientValueCoorSect3 = clientLabelDetail.sectThree.normal;
  let clientValueCoorSect4 = clientLabelDetail.sectFour.normal;
  let clientValueCoorSectThermal = clientLabelDetail.sectThermal.normal;
  if (clientValue.length === 3) {
    clientValueCoorSect1 = clientLabelDetail.sectOne.over;
    clientValueCoorSect2 = clientLabelDetail.sectTwo.over;
    clientValueCoorSect3 = clientLabelDetail.sectThree.over;
    clientValueCoorSect4 = clientLabelDetail.sectFour.over;
    clientValueCoorSectThermal = clientLabelDetail.sectThermal.over;
  }

  return {
    clientValueCoorSect1: clientValueCoorSect1,
    clientValueCoorSect2: clientValueCoorSect2,
    clientValueCoorSect3: clientValueCoorSect3,
    clientValueCoorSect4: clientValueCoorSect4,
    clientValueCoorSectThermal: clientValueCoorSectThermal
  };
};

export const convertQRCode = async (text: string) => {
  return QRCode.toDataURL(text, { errorCorrectionLevel: "Q" }).then(
    (url: string) => {
      return url;
    }
  );
};

export const convertJsBarcode = async (text: string) => {
  const canvas = document.createElement("canvas");
  JsBarcode(canvas, text, {
    textAlign: "left",
    displayValue: false,
    fontSize: 16,
  });
  return canvas.toDataURL("image/png");
}

export const abbreviationVendorCode = (
  sttVendorName: string,
  sttDistrictType: string
) => {
  if (sttVendorName) {
    return sttDistrictType.toLowerCase().includes("lanjutan")
      ? `${sttVendorName.charAt(0).toUpperCase()}L`
      : sttVendorName.charAt(0).toUpperCase();
  } else {
    if (sttDistrictType === "pt-pos-lanjutan") {
      return `PL`;
    } else if (sttDistrictType === "pt-pos") {
      return `P`;
    } else {
      return ``;
    }
  }
};

export const estimationBound = (
  shipmentAlgo: string,
  bookingType: string,
  accountType: string
) => {
  if (
    bookingType === "shipment" &&
    !prefixAlgoEstimationShow(shipmentAlgo) &&
    accountType === "partner"
  ) {
    // shipment type by pos
    return true;
  } else if (bookingType === "manual" && accountType === "partner") {
    // manual type by pos
    return false;
  } else if (bookingType === "manual" && accountType === "client") {
    // manual type by client
    return true;
  } else if (bookingType === "client" && accountType === "partner") {
    // client type by pos
    return true;
  } else if (bookingType === "client" && accountType === "internal") {
    // client type by internal
    return true;
  } else return bookingType === "client" && accountType === "client";
};

export const vendorPickupDetail = (
  sttVendorName: string,
  sttDistrictType: string
) => {
  const vendorMap: any = {
    NINJA: "NX",
    NX: "NX",
    JNE: "JN"
  };

  const vendor = vendorMap[sttVendorName] ?? sttVendorName;

  if (sttVendorName) {
    if (sttDistrictType === "vendor-lanjutan") {
      return `Paket Diambil di ${vendor}`;
    } else if (sttDistrictType === "vendor") {
      return `Paket Diantar oleh ${vendor}`;
    } else {
      return "";
    }
  } else {
    return "";
  }
};

export const getVendorCode = (sttVendorName: string) => {
  const vendorMap: any = {
    NINJA: "NX",
    NX: "NX",
    JNE: "JN"
  };

  return vendorMap[sttVendorName] ?? "";
};

export const isVendorAvailable = (vendor: string, section: number) => {
  const rect = {
    sectionOneAndTwo: {
      normal: {
        vendorSectionHeight: 90,
        commoditySectionHeight: 93,
        bookedBySection: 99
      },
      vendorAvailable: {
        vendorSectionHeight: 81,
        commoditySectionHeight: 85,
        bookedBySection: 89
      }
    },
    sectionThreeAndFour: {
      normal: {
        vendorSectionHeight: 234,
        commoditySectionHeight: 237,
        bookedBySection: 242
      },
      vendorAvailable: {
        vendorSectionHeight: 225,
        commoditySectionHeight: 229,
        bookedBySection: 233
      }
    }
  };

  if (section === 1 || section === 2) {
    return vendor === ""
      ? rect.sectionOneAndTwo.normal
      : rect.sectionOneAndTwo.vendorAvailable;
  } else {
    return vendor === ""
      ? rect.sectionThreeAndFour.normal
      : rect.sectionThreeAndFour.vendorAvailable;
  }
};

export const DOPositions = (clientLabel: string, section: number) => {
  const rect: any = {
    0: {
      normal: {
        background: {
          x: 79,
          y: 94
        },
        text: {
          x: 81.5,
          y: 99
        }
      },
      over: {
        background: {
          x: 90,
          y: 94
        },
        text: {
          x: 92.5,
          y: 99
        }
      }
    },
    1: {
      normal: {
        background: {
          x: 82,
          y: 94
        },
        text: {
          x: 84.5,
          y: 99
        }
      },
      over: {
        background: {
          x: 93,
          y: 94
        },
        text: {
          x: 95.5,
          y: 99
        }
      }
    },
    2: {
      normal: {
        background: {
          x: 183,
          y: 94
        },
        text: {
          x: 185.5,
          y: 99
        }
      },
      over: {
        background: {
          x: 194,
          y: 94
        },
        text: {
          x: 196.5,
          y: 99
        }
      }
    },
    3: {
      normal: {
        background: {
          x: 82,
          y: 238
        },
        text: {
          x: 84.5,
          y: 243
        }
      },
      over: {
        background: {
          x: 93,
          y: 238
        },
        text: {
          x: 95.5,
          y: 243
        }
      }
    },
    4: {
      normal: {
        background: {
          x: 183,
          y: 238
        },
        text: {
          x: 185.5,
          y: 243
        }
      },
      over: {
        background: {
          x: 194,
          y: 238
        },
        text: {
          x: 196.5,
          y: 243
        }
      }
    }
  };

  if (clientLabel) {
    return rect[section].normal;
  }

  return rect[section].over;
};

export const isCourierRecommendation = (prefixRefNo: string) => {
  const prefixList: Array<string> = ["TKP01"];
  const prefixBucket: Array<string> = [];

  for (const item of prefixList) {
    if (prefixRefNo.includes(item)) {
      prefixBucket.push(item);
    }
  }

  return prefixBucket.length > 0;
};

export const convertSla = (sla: string) => {
  return sla.replace(/[^\d.-]/g, "");
};

export const handleNameWithLabels = (data: PrintDataEntitas, isA4 = true) => {
  let nameWidth = 95;
  let countLabel = 0;
  if (!isA4) {
    nameWidth = 92;
  }
  if (!data.sttVendorRefferenceNo) {
    if (data.isDangerousGoods) {
      countLabel += 1;
    }
    if (data.sttShipmentSource) {
      countLabel += 1;
    }
    if (data.sttIsDo) {
      countLabel += 1;
    }
    if (countLabel >= 1) {
      nameWidth -= 12;
    }
    if (countLabel >= 2) {
      nameWidth -= 11;
    }
    if (countLabel >= 3) {
      nameWidth -= 11;
    }
  }
  return nameWidth;
};

export const splitBookedName = (params: {
  doc: jsPDF;
  bookedName: string;
  nameWidth: number;
}) => {
  const maxLine = 3;
  const { doc, bookedName, nameWidth } = params;
  let res = doc.splitTextToSize(bookedName, nameWidth);
  if (res.length > maxLine) {
    const lastLineIndex = maxLine - 1;
    res[lastLineIndex] = `${res[lastLineIndex].substr(
      0,
      res[lastLineIndex].length - 3
    )}...`;
    res = res.slice(0, maxLine);
  }
  return res;
};

export const maskPhone = (phone: string) => {
  if (!phone) {
    return;
  }
  const regexPattern =
    phone.length < 7 ? /(\+?\d{1})(\d+)(\d{1})/g : /(\+?\d{3})(\d+)(\d{2})/g;
  return phone.replace(
    regexPattern,
    (match: any, start: any, middle: any, end: any) => {
      return start + "*".repeat(middle.length) + end;
    }
  );
};

export const badWordsMasking = (senteces: string) => {
  if (senteces) {
    const badWord: any = {
      DUREX: "OFFICIAL STORE RB",
      "DUREX STORE": "OFFICIAL STORE RB",
      "SUTRA FIESTA SHOP": "PT. DHARMENDRA KUMAR TRIYAGI INDONESIA",
      "SUTRA FIESTA ANDALAN OFFICIAL": "PT. DHARMENDRA KUMAR TRIYAGI INDONESIA"
    };

    return badWord[senteces.toUpperCase()] ?? senteces;
  }

  return "";
};
