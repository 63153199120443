import { ellipsisString, uuid } from "@/app/infrastructures/misc/Utils";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import jsPDF from "jspdf";

const isBossPackOrSameDay = (data: any) => {
  const validTypes = ["BOSSPACK", "SAMEDAY"];
  return validTypes.includes(data);
};
const getPackAbbreviation = (fullName: string) => {
  const abbreviations: any = {
    BOSSPACK: "BOSS",
    REGPACK: "REG",
    JAGOPACK: "JAGO",
    ONEPACK: "ONE",
    INTERPACK: "INTER",
    BIGPACK: "BIG",
    JUMBOPACK: "JUMBO",
    SAMEDAY: "SAME",
    OTOPACK150: "OTO15",
    OTOPACK250: "OTO25",
  };

  return abbreviations[fullName] || fullName; // Return the abbreviation or the original fullName if not found
};

export const headerSection = async (
  params: {
    doc: jsPDF | any;
    bookingType: string;
    accountType: string;
    data: PrintDataEntitas;
    pieceData: any;
    barcode: string;
    vendorRefCode: string;
    barcodeValue: string;
    vendorRefCodeValue: string;
    logo: string;
    shipmentId: string;
    startX: number;
    startY: number;
  },
  logoAspectRatio: number
) => {
  const newLogo = await import(
    "@/app/ui/assets/images/new-logo-lion-parcel-black.png"
  );
  params.doc.addImage(
    newLogo.default,
    "PNG",
    params.startX + 5,
    params.startY + 3,
    32,
    logoAspectRatio * 32,
    "logo",
    "FAST",
    0
  );

  // DG SECTION
  if (params.data.isDangerousGoods) {
    params.doc.setLineWidth(0.3);
    params.doc.setFillColor("#333333");
    params.doc.setDrawColor("#333333");
    params.doc.roundedRect(params.startX + 44, params.startY + 3, 10, 8, 0.5, 0.5, "FD");
    params.doc.setFont("Montserrat", "bold");
    params.doc.setTextColor("#FFFFFF");
    params.doc.setFontSize(15);
    params.doc.text("DG", params.startX + 45, params.startY + 8.5);
  }

  // PRODUCT SECTION
  params.doc.setLineWidth(0.3);
  params.doc.setFillColor("#333333");
  params.doc.setDrawColor("#333333");
  if (isBossPackOrSameDay(params.data.sttProductType)) {
    params.doc.roundedRect(params.startX + 55, params.startY + 3, 19, 8, 0.5, 0.5, "FD");
  }
  params.doc.setFont("Montserrat", "bold");
  params.doc.setTextColor(
    isBossPackOrSameDay(params.data.sttProductType) ? "#FFFFFF" : "#333333"
  );
  params.doc.setFontSize(15);
  params.doc.text(
    getPackAbbreviation(params.data.sttProductType),
    getPackAbbreviation(params.data.sttProductType).length > 4 ? params.startX + 76 : params.startX + 72,
    params.startY + 9,
    { align: "right" }
  );

  // GENESIS BARCODE
  params.doc.addImage(
    params.barcode,
    "PNG",
    params.startX + 5,
    params.startY + 19,
    69,
    15,
    uuid(),
    "FAST",
    0
  );
  params.doc.setFont("Montserrat", "bold");
  params.doc.setTextColor("#333333");
  params.doc.setFontSize(18);
  params.doc.text(params.barcodeValue, params.startX + 5, params.startY + 19);

  // NO REF SECTION
  params.doc.setFont("Montserrat", "normal");
  params.doc.setFontSize(6);
  params.doc.text("Ref: ", params.startX + 5, params.startY + 35);
  params.doc.setFont("Montserrat", "bold");
  params.doc.text(
    params.data.sttNoRefExternal ?  ellipsisString(params.data.sttNoRefExternal, 35)  : "-",
    params.startX + 10,
    params.startY + 35
  );

  // CLIENT MOTHER NAME
  const elipsisClientName =
    params.data.sttClientName.length > 40
      ? `${params.data.sttClientName.substring(0, 40)}...`
      : params.data.sttClientName;
  params.doc.text(elipsisClientName, params.startX + 5, params.startY + 38);

  // LINE HORIZONTAL
  params.doc.setLineWidth(0.4);
  params.doc.line(params.startX, params.startY + 40, params.startX + 105, params.startY + 40);

  // LINE VERTICAL
  params.doc.setLineWidth(0.4);
  params.doc.line(params.startX + 77, params.startY + 40, params.startX + 77, params.startY);

  const endContentY = 40

  return {
    ...params,
    startX: 77,
    startY: endContentY
  };
};