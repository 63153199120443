import { ellipsisString } from "@/app/infrastructures/misc/Utils";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import jsPDF from "jspdf";

export const QuoteSection = (
    params: {
        doc: jsPDF | any;
        bookingType: string;
        accountType: string;
        data: PrintDataEntitas;
        pieceData: any;
        barcode: string;
        vendorRefCode: string;
        barcodeValue: string;
        vendorRefCodeValue: string;
        logo: string;
        shipmentId: string;
        startX: number;
        startY: number;
    },
    translate: Function
) => {

    params.doc.setLineWidth(0.3);
    params.doc.line(0, params.startY + 102, params.startX + 105, params.startY + 102);
    params.doc.setFont("Montserrat", "italic");
    params.doc.setTextColor("#333333");
    params.doc.setFontSize(8);
    params.doc.text(params.doc.splitTextToSize(ellipsisString(params.data.campaignSttQuote, 120), 90), params.startX + 51, params.startY + 96.5, { align: 'center' });
    const endContentY = params.startY + 20
    return {
        ...params,
        startX: 0,
        startY: endContentY + 5
    }
}