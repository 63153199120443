
import { Vue, Options } from "vue-class-component";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import {
  checkRolePermission,
  formatDateWithoutTime
} from "@/app/infrastructures/misc/Utils";
import { BulkBookingController } from "@/app/ui/controllers/BulkBookingController";
import { BULK_BOOKING_CLIENT_TEMPLATE } from "@/app/infrastructures/misc/RolePermission";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";

@Options({
  components: {
    EmptyState
  }
})
export default class ClientBookingTabs extends Vue {
  mounted() {
    this.onRequestFetchClientBookingList();
    BulkBookingController.setFirstRequest(true);
  }
  onRequestFetchClientBookingList() {
    BulkBookingController.fetchClientBookingList({
      search: this.searchValue
    });
  }
  get dataTable() {
    return BulkBookingController.clientBookingList.data;
  }
  // Pagination Table
  get pagination() {
    return BulkBookingController.clientBookingList.pagination;
  }

  get isFirstRequest() {
    return BulkBookingController.isFirstRequest;
  }

  get errorCause() {
    return BulkBookingController.errorCause;
  }

  // Table
  get columns() {
    return [
      {
        name: "ID Config",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class='text-left text-black-lp-300 flex justify-center'>${item.id}</div>`;
        }
      },
      {
        name: "ID Client",
        styleHead: "w-24 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class='text-left text-black-lp-300 flex justify-center'>${item.accountId}</div>`;
        }
      },
      {
        name: "Client Name",
        styleHead: "w-44 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class="overflow-ellipsis text-black-lp-300">${item.accountName}</div>`;
        }
      },
      {
        name: "Dibuat Oleh",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<div class='flex text-black-lp-300 text-12px'>${item.createdBy}</div>`;
        }
      },
      {
        name: "Tanggal Dibuat",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<span class="text-black-lp-300">${formatDateWithoutTime(
            item.createdAt
          )}</span>`;
        }
      },
      {
        name: "Diubah Oleh",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `
        <div class='flex text-black-lp-300 text-12px'>${item.createdBy}</div>`;
        }
      },
      {
        name: "Tanggal Diubah",
        styleHead: "w-36 text-left whitespace-no-wrap",
        render: (item: any) => {
          return `<span class="text-black-lp-300">${formatDateWithoutTime(
            item.createdAt
          )}</span>`;
        }
      }
    ];
  }

  get isDetailAble() {
    return checkRolePermission(BULK_BOOKING_CLIENT_TEMPLATE.DETAIL);
  }
  goToDetail(item: any) {
    this.$router.push(`/shipment/bulks/klien-book/${item.id}`);
  }

  get isCreateAble() {
    return checkRolePermission(BULK_BOOKING_CLIENT_TEMPLATE.CREATE);
  }
  create() {
    this.$router.push(`/shipment/bulks/klien-book/create-klien-booking`);
    GTMCommonEvent("client_template_upload_clicked");
  }

  get isLoading() {
    return BulkBookingController.isLoading;
  }

  onRequest() {
    this.onRequestFetchClientBookingList();
  }

  searchValue = "";
  onSearch(value: string) {
    this.searchValue = value;
    this.onRequestFetchClientBookingList();
  }
  clearSearch() {
    this.searchValue = "";
    this.onRequestFetchClientBookingList();
  }
}
