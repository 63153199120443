import jsPDF from "jspdf";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import { estimationBound, formatPrice } from "../../module";
import startCase from "lodash/startCase";
import { FlagsFeatureShipmentC1 } from "@/feature-flags/flags-shipment-c1";

export const RecipientBreakdownSection = (
  params: {
    doc: jsPDF | any;
    bookingType: string;
    accountType: string;
    data: PrintDataEntitas;
    pieceData: any;
    barcode: string;
    vendorRefCode: string;
    barcodeValue: string;
    vendorRefCodeValue: string;
    logo: string;
    shipmentId: string;
    startX: number;
    startY: number;
  },
  translate: Function
) => {
  const leftMargin = params.startX + 31;
  let snkPositionY = params.startY + 126;

  // box
  params.doc.setDrawColor("#000000");
  params.doc.setLineDash([], 0);
  params.doc.setLineWidth(0.3);
  params.doc.setFillColor(255, 255, 255);
  params.doc.roundedRect(leftMargin, snkPositionY, 72, 17, 1, 1, "FD");
  // line
  params.doc.setDrawColor("#000000");
  params.doc.setLineDash([], 0);
  params.doc.line(
    leftMargin + 28,
    snkPositionY,
    leftMargin + 28,
    snkPositionY + 17
  );

  let shipmentId = params.data.sttShipmentId ? params.data.sttShipmentId : "";
  let isPriceOfCOD = params.data.sttIsCod;
  let priceString = "-";
  let bKirimValue = "-";
  let bLainnyaValue = "-";
  let pajakPmString = "-";
  let showPajak = true;
  const pajakValue =
    Number(params.data.sttBmTaxRate) +
    Number(params.data.sttPpnTaxRate) +
    Number(params.data.sttPphTaxRate);
  if (
    params.data.isReverseJourney &&
    (shipmentId.startsWith(
      FlagsFeatureShipmentC1.feature_shipment_c1.getValue()
    ) ||
      shipmentId.match(/^AP|^AS/gi))
  ) {
    shipmentId = params.data.sttReverseJourneyShipmentId;
    isPriceOfCOD = params.data.sttReverseJourneyCodHandling;
  }
  
  if (params.data.isReverseJourney) {
    shipmentId = params.data.sttReverseJourneyShipmentId;
  }
  const isEstimationBound = estimationBound(
    shipmentId,
    params.bookingType,
    params.accountType,
    isPriceOfCOD
  );

  if (!isEstimationBound) {
    priceString = formatPrice(
      params.data.sttBookedByCountry,
      params.data.totalTariff || 0
    );

    const bKirimCalculation =
      params.data.sttShippingSurchargeRateAfterDiscount +
      params.data.sttPublishRateAfterDiscount +
      params.data.sttOriginDistrictRateAfterDiscount +
      params.data.sttDestinationDistrictRateAfterDiscount;

    bKirimValue = formatPrice(
      params.data.sttBookedByCountry,
      bKirimCalculation
    );

    const bLainnyaValueCalculation = params.data.sttIsPromo
      ? Number(params.data.sttCommoditySurchargeRateAfterDiscount) +
        Number(params.data.sttHeavyweightSurchargeRateAfterDiscount) +
        Number(params.data.sttDocumentSurchargeRateAfterDiscount) +
        Number(params.data.sttInsuranceRate) +
        Number(params.data.sttCodFee) +
        Number(params.data.sttWoodpackingAfterRateDiscount)
      : Number(params.data.sttCommoditySurchargeRate) +
        Number(params.data.sttHeavyweightSurchargeRate) +
        Number(params.data.sttDocumentSurchargeRate) +
        Number(params.data.sttInsuranceRate) +
        Number(params.data.sttCodFee) +
        Number(params.data.sttWoodpackingRate);

    bLainnyaValue = formatPrice(
      params.data.sttBookedByCountry,
      bLainnyaValueCalculation
    );

    pajakPmString = formatPrice(params.data.sttBookedByCountry, pajakValue);

    showPajak = pajakValue > 0;
  } else {
    if (pajakValue > 0) {
      pajakPmString = "-";
    } else {
      showPajak = false;
    }
  }

  const tariffTitle = params.data.sttIsCod
    ? "COD"
    : startCase(translate("receipt.totalCost"));
  params.doc.setTextColor("#333333");
  params.doc.setFont("Montserrat", "bold");
  let tariffTitlePadding = 0;

  snkPositionY += 2.5;
  if (params.data.sttIsCod || showPajak) {
    snkPositionY -= 2.5;
  }

  if (params.data.sttIsCod) {
    params.doc.setFontSize(7);
    tariffTitlePadding = 7;
    priceString = formatPrice(
      params.data.sttBookedByCountry,
      params.data.sttCodAmount
    );
  } else {
    params.doc.setFontSize(5);
    tariffTitlePadding = 12;
    params.doc.text(":", leftMargin + 13, snkPositionY + 4);
  }

  params.doc.text(tariffTitle, leftMargin + 2, snkPositionY + 4);

  params.doc.setFont("Montserrat", "normal");
  params.doc.text(
    priceString,
    leftMargin + 2 + tariffTitlePadding,
    snkPositionY + 4
  );

  const tariffPositionY = snkPositionY + 7;
  const tariffPositionYL2 = tariffPositionY + 2;
  const tariffPositionYL3 = tariffPositionYL2 + 3;
  const tariffPositionYL4 = tariffPositionYL3 + 2;

  params.doc.setFontSize(5);
  params.doc.setFont("Montserrat", "bold");
  params.doc.text(
    translate("receipt.shippingCostV2"),
    leftMargin + 2,
    tariffPositionY
  );
  params.doc.text(
    translate("receipt.othersV2"),
    leftMargin + 2,
    tariffPositionYL2
  );

  params.doc.setFont("Montserrat", "normal");
  params.doc.text(":", leftMargin + 13, tariffPositionY);
  params.doc.text(":", leftMargin + 13, tariffPositionYL2);
  params.doc.text(bKirimValue, leftMargin + 14, tariffPositionY); // B. Kirim
  params.doc.text(bLainnyaValue, leftMargin + 14, tariffPositionYL2); // B. Lainnya

  if (!isEstimationBound && params.data.sttIsCod) {
    params.doc.setFont("Montserrat", "bold");
    params.doc.setTextColor("#78787A");
    params.doc.text(
      translate("receipt.returnFeeV2"),
      leftMargin + 2,
      tariffPositionYL3
    );
    params.doc.setFont("Montserrat", "normal");
    params.doc.text(":", leftMargin + 13, tariffPositionYL3);
    params.doc.text(
      params.data.sttIsCod
        ? formatPrice(
            params.data.sttBookedByCstountry,
            params.data.sttReturnFee
        ) : "-",
      leftMargin + 14,
      tariffPositionYL3
    ); // B. Retur
  }

  // pajak

  if (showPajak) {
    params.doc.setFont("Montserrat", "bold");
    params.doc.setTextColor("#78787A");
    params.doc.text(
      translate("receipt.taxBm"),
      leftMargin + 2,
      tariffPositionYL4
    );
    params.doc.setFont("Montserrat", "normal");
    params.doc.text(":", leftMargin + 13, tariffPositionYL4);
    params.doc.text(pajakPmString, leftMargin + 14, tariffPositionYL4); // Pajak/BM
  }
};
