
/* eslint-disable @typescript-eslint/camelcase */
import { PropType } from "vue";
import { Vue, Options } from "vue-class-component";
import debounce from "lodash/debounce";
import UploadFile from "@/app/ui/components/upload-file/index.vue";
import { PosClientController } from "@/app/ui/controllers/PosClientController";
import { BulkBookingController } from "@/app/ui/controllers/BulkBookingController";
import { GTMCommonEvent } from "@/app/infrastructures/misc/gtm-event/common-event";

@Options({
  components: {
    UploadFile
  },
  emits: ["request"],
  props: {
    onClose: {
      default: Function as PropType<() => void>,
      type: Function
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          page: 1,
          limit: 5
        };
      }
    }
  }
})
export default class ModalBulkBooking extends Vue {
  props: any = this.$props;
  get form(): any {
    return BulkBookingController.form;
  }
  maxRow = 100;

  mounted() {
    BulkBookingController.setStatusClearFile(false);
  }

  get isClearFile() {
    return BulkBookingController.isClearFile;
  }

  //  Is disabled
  get isDisabled() {
    return (
      !this.form.activityName ||
      typeof this.form.archiveFile !== "object" ||
      !this.form.clientName.name ||
      this.form.clientName.isBanned ||
      !this.form.formatTemplate
    );
  }

  resultUpload = "";
  async _onAdd() {
    const resp = await BulkBookingController.uploadBulkBooking({
      activityName: this.form.activityName,
      posClientId: this.form.clientName.id,
      posClientType: this.form.clientName.type,
      archiveFile: this.form.archiveFile,
      templateFormat: this.form.formatTemplate
    });
    this.resultUpload = resp?.message;
    this.resetForm();
    GTMCommonEvent("upload_bulk_booking_upload_clicked");
  }

  resetForm() {
    const reset = {
      type: "Bulk Booking",
      activityName: "",
      archiveFile: "",
      clientName: "",
      formatTemplate: ""
    };
    BulkBookingController.setFormAddBulkBooking(reset);
    BulkBookingController.setStatusClearFile(false);
  }
  get isUploadFailed() {
    return this.resultUpload === "internet" || this.resultUpload === "server";
  }
  onCloseErrorUpload() {
    this.resultUpload = "";
  }

  get isOpenBulkBooking() {
    return BulkBookingController.IsOpenUploadBulkBooking;
  }

  goToBulkDownload() {
    this.$router.push("/bulk-action/bulk-download");
  }

  onCloseUploadBulkBooking() {
    this.props.pagination.page = 1;
    this.$emit("request");
    BulkBookingController.setIsOpenUploadBulkBooking(false);
  }

  created() {
    BulkBookingController.setClientName("");
  }

  isOpenSelect = false;
  onOpenSelect() {
    this.isOpenSelect = true;
  }

  onCloseSelect() {
    this.isOpenSelect = false;
  }

  get posClientList() {
    const list = [
      {
        id: 0,
        name: "Pilih client / partner",
        status: "",
        isBanned: false,
        type: ""
      }
    ];
    PosClientController.posList.list.forEach((item: any) => {
      if (item.type !== "pos")
        list.push({
          id: item.id,
          name: item.name,
          status: item.status,
          isBanned: item.isBanned,
          type: item.type
        });
    });
    return list;
  }

  fetchPosClient = debounce((search: string) => {
    if (search.length > 2 || !search) {
      PosClientController.getPosClientList(search);
    }
  });

  get isLoadingPosClient() {
    return PosClientController.isLoading;
  }

  get listTemplate() {
    return [
      {
        name: "Pilih Template Format",
        value: ""
      },
      {
        name: "Genesis Template",
        value: "genesis_template"
      },
      {
        name: "Client Template",
        value: "client_template"
      }
    ];
  }
  isOpenSelectTemplate = false;
  get filterTemplate() {
    return this.form.formatTemplate;
  }

  onOpenSelectTemplate() {
    this.isOpenSelectTemplate = true;
  }
  onCloseSelectTemplate() {
    this.isOpenSelectTemplate = false;
  }
  onSelectTemplate(name: string, value: string) {
    BulkBookingController.setFormatTemplate(value);
    this.onCloseSelectTemplate();
  }
}
