/* eslint-disable @typescript-eslint/camelcase */
import { toFormDataUtils } from "@/app/infrastructures/misc/Utils";
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import { BulkBookingUploadRequestInterface } from "../contracts/BulkBookingRequest";

export class BulkBookingApiRequest
  implements BulkBookingUploadRequestInterface {
  readonly archive_type: string = "stt";
  activity_name: string;
  archive_file: string;
  bulk_booking_to_partner_id: number;
  bulk_booking_type_partner: string;
  bulk_booking_template_type: string;

  constructor(
    activityName: string,
    archiveFile: any,
    bulk_booking_to_partner_id: number,
    bulk_booking_type_partner: string,
    bulk_booking_template_type: string
  ) {
    this.activity_name = activityName;
    this.archive_file = archiveFile;
    this.bulk_booking_to_partner_id = bulk_booking_to_partner_id;
    this.bulk_booking_type_partner = bulk_booking_type_partner;
    this.bulk_booking_template_type = bulk_booking_template_type;
  }

  public toFormData() {
    const data = {
      archive_type: this.archive_type,
      activity_name: this.activity_name,
      archive_file: this.archive_file,
      bulk_booking_to_partner_id: this.bulk_booking_to_partner_id,
      bulk_booking_type_partner: this.bulk_booking_type_partner,
      bulk_booking_template_type: this.bulk_booking_template_type
    };
    return toFormDataUtils(data);
  }
}

export class ClientBookingListParams {
  page = 0;
  limit = 0;
  isTotalData = false;
  search = "";
  constructor(fields?: Partial<ClientBookingListParams>) {
    Object.assign(this, fields);
  }
  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
