import jsPDF from "jspdf";
import { PrintDataEntitas } from "@/domain/entities/Booking";

export const KoliSection = (
  params: {
    doc: jsPDF | any;
    bookingType: string;
    accountType: string;
    data: PrintDataEntitas;
    pieceData: any;
    barcode: string;
    vendorRefCode: string;
    barcodeValue: string;
    vendorRefCodeValue: string;
    logo: string;
    shipmentId: string;
    startX: number;
    startY: number;
  },
  translate: Function
) => {
  const leftMargin = params.startX + 78;

  params.doc.setTextColor("#333333");
  params.doc.setFontSize(26);

  const koliPositionY = params.startY + 67.5;
  params.doc.setFont("Montserrat", "bold");
  params.doc.text(
    params.pieceData.stt_piece_count,
    leftMargin,
    koliPositionY,
    null,
    null,
    "left"
  );

  const endContentY = koliPositionY + 2.5;

  params.doc.setDrawColor("#333333");
  params.doc.setLineDash([], 0);
  params.doc.setLineWidth(0.4);
  params.doc.line(
    params.startX + 77,
    endContentY,
    params.startX + 105,
    endContentY
  );
};
