import jsPDF from "jspdf";
import { PrintDataEntitas } from "@/domain/entities/Booking";
import startCase from "lodash/startCase";
import JsBarcode from "jsbarcode";
import uuid from "@/app/infrastructures/misc/common-library/Uuid";
import {
  vendorPickupDetail,
  maskPhone,
  badWordsMasking
} from "@/app/ui/modules/receipt-v2/module";
import { ellipsisString } from "@/app/infrastructures/misc/Utils";

export const createRoundRect = (doc: jsPDF | any, text: string, x: number, y: number) => {
  doc.setFontSize(8);
  doc.setDrawColor(0);
  doc.setFillColor(0, 0, 0);

  doc.roundedRect(x, y, doc.getTextDimensions(text).w + 2, doc.getTextDimensions(text).h + 2, 1, 1, "F");

  doc.setTextColor("#FFFFFF");
  doc.setFont("Montserrat", "bold");
  doc.text(text, x + 1, y + 3.25);
  return {
    w: doc.getTextDimensions(text).w + 2,
    h: doc.getTextDimensions(text).h + 2
  };
};

export const DeliverySection = (
  params: {
    doc: jsPDF | any;
    bookingType: string;
    accountType: string;
    data: PrintDataEntitas;
    pieceData: any;
    barcode: string;
    vendorRefCode: string;
    barcodeValue: string;
    vendorRefCodeValue: string;
    logo: string;
    shipmentId: string;
    startX: number;
    startY: number;
  },
  translate: Function,
  language = "id"
) => {

  const leftMargin = params.startX + 5;
  const rightEndX = params.startX + 77;

  // PENGIRIM
  params.doc.setTextColor("#333333");
  params.doc.setFontSize(6);

  const senderPositionY = params.startY + 40 + 5;
  params.doc.setFont("Montserrat", "bold");
  params.doc.text(
    startCase(translate("receipt.shipper")).toUpperCase() + ":",
    leftMargin,
    senderPositionY
  );
  params.doc.setFont("Montserrat", "normal");

  const sttSenderNameData =
    params.data.sttSenderName.length > 20
      ? `${params.data.sttSenderName.substring(0, 20)}...`
      : params.data.sttSenderName;

  params.doc.text(
    `${badWordsMasking(sttSenderNameData).toUpperCase()} ${maskPhone(
      params.data.sttSenderPhone
    )}`,
    params.startX + 17,
    senderPositionY
  );

  // PENERIMA
  params.doc.setFontSize(7);
  const recipientPositionY = senderPositionY + 3.5;
  params.doc.setFont("Montserrat", "bold");
  params.doc.text(
    startCase(translate("receipt.receiver")).toUpperCase() + ":",
    leftMargin,
    recipientPositionY
  );
  params.doc.setFont("Montserrat", "normal");

  const sttRecipientName =
    params.data.sttRecipientName.length > 20
      ? `${params.data.sttRecipientName.substring(0, 20)}...`
      : params.data.sttRecipientName;

  params.doc.text(
    `${badWordsMasking(sttRecipientName).toUpperCase()}`,
    params.startX + 20.5,
    recipientPositionY
  );

  // PENERIMA PHONE
  let phoneRecipientPositionY = recipientPositionY + 3;
  params.doc.setFont("Montserrat", "bold");
  params.doc.text(
    params.data.sttRecipientPhone,
    leftMargin,
    phoneRecipientPositionY
  );

  params.doc.setFont("Montserrat", "normal");
  const indentParagraph =
    params.data.sttRecipientPhone.length > 13
      ? "                                    "
      : params.data.sttRecipientPhone.length > 11
      ? "                                 "
      : "                           ";
  const phoneRecipientPostalCodeStrong = indentParagraph + ellipsisString(params.data.sttRecipientAddress, 200);
  const recipientAddress = params.doc.splitTextToSize(
    phoneRecipientPostalCodeStrong,
    70
  );

  params.doc.text(recipientAddress, leftMargin, phoneRecipientPositionY);

  if (recipientAddress.length > 3) {
    phoneRecipientPositionY += 1.5;
  }

  // DESTINATION DISTRICT NAME
  const districtPositionY = phoneRecipientPositionY + 13.5;

  params.doc.setFontSize(8);
  params.doc.setFont("Montserrat", "bold");

  const splitedSTTDestinationDistrictName = params.doc.splitTextToSize(
    params.data.sttDestinationDistrictName.toUpperCase(),
    65
  );

  params.doc.text(
    splitedSTTDestinationDistrictName,
    leftMargin,
    districtPositionY
  );

  // FLAG STT
  let sttPositionX = leftMargin;
  const sttPositionY = districtPositionY + 6;
  params.data.sttFlag.forEach((item: string) => {
    const rect = createRoundRect(params.doc, item, sttPositionX, sttPositionY);
    sttPositionX += rect.w + 2;
  });

  // COMMODITY

  const sttCommodityNamelanguage =
    language === "en"
      ? params.data.sttCommodityDescription
      : params.data.sttCommodityName;

  const sttCommodityNameString =
    sttCommodityNamelanguage.length > 30
      ? `${sttCommodityNamelanguage.substring(0, 30)}...`
      : sttCommodityNamelanguage;

  const sttCommodityName = params.doc.splitTextToSize(
    sttCommodityNameString,
    50
  );

  params.doc.setFont("Montserrat", "normal");
  params.doc.setTextColor("#333333");
  params.doc.setFontSize(6);
  params.doc.text(
    sttCommodityName,
    rightEndX - 1,
    sttPositionY + 2,
    null,
    null,
    "right"
  );

  // AWB Vendor - static position
  const awbPositionY = params.startY + 84;
  const barcodePositionY = awbPositionY + 1;
  const sttVendorRefferenceNo = params.data.sttVendorRefferenceNo;
  if (sttVendorRefferenceNo !== "") {
    params.doc.setFont("Montserrat", "bold");
    params.doc.setFontSize(9);
    params.doc.text(sttVendorRefferenceNo, leftMargin, awbPositionY);

    const canvas = document.createElement("canvas");
    JsBarcode(canvas, sttVendorRefferenceNo, {
      displayValue: false,
      margin: 0
    });
    const jpegUrl = canvas.toDataURL("image/jpeg");
    params.doc.addImage(
      jpegUrl,
      "PNG",
      leftMargin + 2,
      barcodePositionY,
      params.doc.getTextDimensions(sttVendorRefferenceNo).w,
      5,
      uuid(),
      "FAST",
      0
    );
  }

  const vendorName = vendorPickupDetail(
    params.data.sttVendorName,
    params.data.sttDistrictType
  );

  if (vendorName) {
    createRoundRect(params.doc, vendorName, rightEndX - 7, barcodePositionY);
  }

  // Bottom line - static position

  const endContentY = params.startY + 92;
  params.doc.setLineDash([], 0);
  params.doc.setLineWidth(0.4);
  params.doc.line(rightEndX, senderPositionY - 5, rightEndX, endContentY);
  params.doc.line(params.startX + 0, endContentY, params.startX + 105, endContentY);

  return {
    ...params,
    startX: rightEndX,
    startY: endContentY
  };
};