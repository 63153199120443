
import { Options, Vue } from "vue-class-component";
import { checkRolePermission } from "@/app/infrastructures/misc/Utils";
import {
  BULK_BOOKING,
  BULK_BOOKING_CLIENT_TEMPLATE
} from "@/app/infrastructures/misc/RolePermission";
import { BulkBookingController } from "@/app/ui/controllers/BulkBookingController";

@Options({
  components: {}
})
export default class BulkBooking extends Vue {
  // Tabs
  allTabs = [
    {
      name: "bulk-book",
      title: "Bulk Booking",
      permission: checkRolePermission(BULK_BOOKING.ENABLE)
    },
    {
      name: "klien-book",
      title: "Client Booking Template",
      permission: checkRolePermission(BULK_BOOKING_CLIENT_TEMPLATE.ENABLE)
    }
  ];
  get tabs() {
    return this.allTabs.filter((tab: any) => tab.permission);
  }

  changeTabs(value: string) {
    this.$router.push({
      name: value
    });
  }

  isTabActive(name: string) {
    return name === this.$route.meta?.tab;
  }

  get isShowButtonSave() {
    return this.$route.name === "klien-book-detail-edit";
  }

  submitEdit() {
    BulkBookingController.editClientTemplateConfiguration({
      id: Number(this.$route.params.id),
      headerMapping: BulkBookingController.detailClientBookingData.headerMapping
    });
  }
}
