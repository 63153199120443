import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pt-4 bg-white flex flex-col text-12px xxl:text-14px overflow-hidden h-screen relative w-full" }
const _hoisted_2 = { class: "flex items-center justify-between my-4 mb-2" }
const _hoisted_3 = { class: "flex" }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_lp_button = _resolveComponent("lp-button")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_TableV2 = _resolveComponent("TableV2")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_Search, {
        onInput: _ctx.onSearch,
        onClear: _ctx.clearSearch,
        value: _ctx.searchValue,
        placeholder: "Cari nama Client",
        class: "w-1/4"
      }, null, 8, ["onInput", "onClear", "value"]),
      _createVNode("div", _hoisted_3, [
        (_ctx.isCreateAble)
          ? (_openBlock(), _createBlock(_component_lp_button, {
              key: 0,
              title: "Upload Client Booking",
              textColor: "white",
              class: "whitespace-nowrap",
              onClick: _ctx.create
            }, null, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.dataTable.length === 0 && !_ctx.isLoading && _ctx.isFirstRequest)
      ? (_openBlock(), _createBlock(_component_EmptyState, {
          key: 0,
          data: "Client Booking"
        }))
      : (_openBlock(), _createBlock("div", _hoisted_4, [
          _createVNode(_component_TableV2, {
            pagination: _ctx.pagination,
            "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.pagination = $event)),
            loading: _ctx.isLoading,
            columns: _ctx.columns,
            data: _ctx.dataTable,
            class: "my-2",
            errorCause: _ctx.errorCause,
            onRequest: _ctx.onRequest,
            onClick: _ctx.goToDetail,
            isDetailAble: _ctx.isDetailAble,
            pinnedSubstractHeight: "300px",
            paginationStyle: "v3"
          }, null, 8, ["pagination", "loading", "columns", "data", "errorCause", "onRequest", "onClick", "isDetailAble"])
        ]))
  ]))
}