import { PrintDataEntitas } from "@/domain/entities/Booking";
import jsPDF from "jspdf";

export const getWaterMark = async (
    params: {
        doc: jsPDF;
        bookingType: string;
        accountType: string;
        data: PrintDataEntitas;
        pieceData: any;
        barcode: string;
        vendorRefCode: string;
        barcodeValue: string;
        vendorRefCodeValue: string;
        logo: string;
        shipmentId: string;
        startX: number;
        startY: number;
    },
) => {
    const isProductSamedayOrBosspack = params.data.sttProductType.toUpperCase() === "BOSSPACK" || params.data.sttProductType.toUpperCase() === "SAMEDAY"
    let result = "";
    if (isProductSamedayOrBosspack) {
        const prefix = params.data.sttProductType.toUpperCase() === "BOSSPACK" ? "bosspack" : "sameday";
        if (params.data.sttIsCod || params.data.sttIsDfod) {
            result = params.data.isDangerousGoods ? `${prefix}_cod_dg` : `${prefix}_cod`;
        } else if (params.data.isDangerousGoods) {
            result = `${prefix}_dg`;
        } else {
            result = prefix;
        }
    } else {
        if (params.data.sttIsCod || params.data.sttIsDfod) {
            result = params.data.isDangerousGoods ? "cod_dg" : "cod";
        } else if (params.data.isDangerousGoods) {
            result = "dg";
        }
    }

    if (result) {
        const watermark = await import(
            `@/app/ui/assets/images/watermark-v2/${result}.png`
        );
        return params.doc.addImage(
            watermark.default,
            "PNG",
            params.startX,
            params.startY,
            104,
            144,
            `${result}`,
            "FAST"
        );
    }
}