import { PrintDataEntitas } from "@/domain/entities/Booking";
import { FlagsPermissionPrintReceipt } from "@/feature-flags/flags-permission-print-receipt";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import { isCourierRecommendation } from "../../module";

export const viralSection = async (
  params: {
    doc: jsPDF | any;
    startX: number;
    startY: number;
    data: PrintDataEntitas;
  },
  coor: {
    startX: number;
    startY: number;
  },
  translate: Function
) => {
  const urlCampaign = `${process.env.VUE_APP_WEBSITE_LION_PARCEL}/ceocard`;

  const isSTTCAOrRetail =
    params.data.sttBookedForName.toLowerCase() === "customer apps" ||
    params.data.sttNo.startsWith("11LP") ||
    params.data.sttNo.startsWith("10LP");
  const viralCampaignCondition = FlagsPermissionPrintReceipt.permission_campaign_viral_enable.isEnabled() &&
  isSTTCAOrRetail && !params.data.isReverseJourney

    const combineQRTextForRegular = `${process.env.VUE_APP_WEBSITE_LION_PARCEL}/track/stt?q=${params.data.sttNo}`;
    const combineQRTextForCourirRecomendation = JSON.stringify({
      "stt_no": params.data.sttNo,
      "partner_name": params.data.sttBookedForName,
      "booking_id": params.data.otherShipperTicketCode,
    });
      let qrcodeValue = "";
      if (viralCampaignCondition) {
        qrcodeValue = urlCampaign
      }else if (isCourierRecommendation(params.data.sttNoRefExternal)) {
        qrcodeValue = combineQRTextForCourirRecomendation;
      } else {
        qrcodeValue = combineQRTextForRegular;
      }

  const qrcode = await QRCode.toDataURL(qrcodeValue || "", {
    errorCorrectionLevel: "Q",
  }).then((url: string) => {
    return url;
  });
  // BOX
  params.doc.roundedRect(
    coor.startX + 49,
    coor.startY + 108,
    54,
    16.5,
    1,
    1
  );

  if (
    viralCampaignCondition
  ) {
    params.doc.setFont("Montserrat", "bold");
    params.doc.setTextColor("#333333");
    params.doc.setFontSize(12);
    params.doc.text("VIRAL", coor.startX + 50, coor.startY + 116);

    params.doc.setFont("Montserrat", "normal");
    params.doc.setTextColor("#333333");
    params.doc.setFontSize(5);
    params.doc.text(
      "Kumpulkan kartu Densu\n& menangkan hadiahnya!",
      coor.startX + 50,
      coor.startY + 119
    );
  } else {
    //IF CAMPAIGN OFF
    params.doc.setFont("Montserrat", "normal");
    params.doc.setTextColor("#333333");
    params.doc.setFontSize(6);
    params.doc.text(
      translate("receipt.infoTracking"),
      coor.startX + 51,
      coor.startY + (params.data.sttProductType === 'INTERPACK' ? 114 : 115)
    );
  }

  params.doc.roundedRect(
    coor.startX + 74,
    coor.startY + 110,
    14,
    13.5,
    0.5,
    0.5
  );
  params.doc.addImage(qrcode, coor.startX + 74.5, coor.startY + 110.5, 12.5, 12.5);

  params.doc.setFont("Montserrat", "bold");
  params.doc.roundedRect(
    coor.startX + 87,
    coor.startY + 110,
    15,
    13.5,
    0.5,
    0.5,
    "FD"
  );
  params.doc.setTextColor("#ffffff");
  params.doc.setFontSize(12);
  params.doc.text("SCAN", coor.startX + 88, coor.startY + 117);
  params.doc.setFontSize(10);
  params.doc.text(params.data.sttProductType === 'INTERPACK' ? "HERE" : "DI SINI", coor.startX + (params.data.sttProductType === 'INTERPACK' ? 89.3 : 88), coor.startY + 120.5);
};
