import { PrintDataEntitas } from "@/domain/entities/Booking";
import jsPDF from "jspdf";
import { codDfodLabel, estimationBound, formatPrice, isCodRetail } from "../../module";
import { startCase } from "lodash";
import { FlagsFeatureShipmentC1 } from "@/feature-flags/flags-shipment-c1";

export const TarifSection = (params: {
    doc: jsPDF;
    bookingType: string;
    accountType: string;
    data: PrintDataEntitas;
    pieceData: any;
    barcode: string;
    vendorRefCode: string;
    barcodeValue: string;
    vendorRefCodeValue: string;
    logo: string;
    shipmentId: string;
    startX: number;
    startY: number;
},
    translate: Function
) => {

    let shipmentId = params.data.sttShipmentId ? params.data.sttShipmentId : "";
    let isPriceOfCOD = params.data.sttIsCod;
    let totalBiaya: any = 0;
    let totalBiayaKirim: any = 0;
    let lainLain: any = 0;
    let pajak: any = 0;
    let showPajak = true;
    const pajakValue =
        Number(params.data.sttBmTaxRate) +
        Number(params.data.sttPpnTaxRate) +
        Number(params.data.sttPphTaxRate);
    if (
      params.data.isReverseJourney &&
      (shipmentId.startsWith(
        FlagsFeatureShipmentC1.feature_shipment_c1.getValue()
      ) ||
        shipmentId.match(/^AP|^AS/gi))
    ) {
      shipmentId = params.data.sttReverseJourneyShipmentId;
      isPriceOfCOD = params.data.sttReverseJourneyCodHandling;
    }

    
    if (params.data.isReverseJourney) {
        shipmentId = params.data.sttReverseJourneyShipmentId;
    }

    if (!estimationBound(shipmentId, params.bookingType, params.accountType, isPriceOfCOD)) {
        //define variable
        totalBiaya = formatPrice(
            params.data.sttBookedByCountry,
            Number(params.data.totalTariff)
        );
        const bKirimCalculation =
            Number(params.data.sttShippingSurchargeRateAfterDiscount) +
            Number(params.data.sttPublishRateAfterDiscount) +
            Number(params.data.sttOriginDistrictRateAfterDiscount) +
            Number(params.data.sttDestinationDistrictRateAfterDiscount);
        totalBiayaKirim = formatPrice(
        params.data.sttBookedByCountry,
        Number(bKirimCalculation)
        );
        const lainLainCalculation = params.data.sttIsPromo
            ?
            Number(params.data.sttCommoditySurchargeRateAfterDiscount) +
            Number(params.data.sttHeavyweightSurchargeRateAfterDiscount) +
            Number(params.data.sttDocumentSurchargeRateAfterDiscount) +
            Number(params.data.sttInsuranceRate) +
            Number(params.data.sttCodFee) + 
            Number(params.data.sttWoodpackingAfterRateDiscount)
            :
            Number(params.data.sttCommoditySurchargeRate) +
            Number(params.data.sttHeavyweightSurchargeRate) +
            Number(params.data.sttDocumentSurchargeRate) +
            Number(params.data.sttInsuranceRate) +
            Number(params.data.sttCodFee) + 
            Number(params.data.sttWoodpackingRate);

        lainLain = formatPrice(
            params.data.sttBookedByCountry,
            lainLainCalculation
          );
        
        pajak = formatPrice(params.data.sttBookedByCountry, pajakValue)
        showPajak = pajakValue > 0;
    } else {
        totalBiaya = "-";
        totalBiayaKirim = "-";
        lainLain = "-";
        if (pajakValue > 0) {
          pajak = "-";
        } else {
          showPajak = false;
        }
    }

    if (params.data.sttIsCod) {
        totalBiaya = formatPrice(
            params.data.sttBookedByCountry,
            Number(params.data.sttCodAmount)
        );
    }

    params.doc.setTextColor("#333333");
    if (codDfodLabel(params.data.sttIsCod, params.data.sttIsDfod) === 'cod' || codDfodLabel(params.data.sttIsCod, params.data.sttIsDfod) === 'dfod') {
        params.doc.setFontSize(13);
        params.doc.setFont("Montserrat", "extrabold");
        params.doc.text("COD", params.startX +78, params.startY + 75);
        params.doc.setFontSize(10);
        params.doc.setFont("Montserrat", "bold");
        params.doc.text(String(totalBiaya), params.startX +78, params.startY + 80);
    } 
    else {
        params.doc.setFont("Montserrat", "bold");
        params.doc.setFontSize(6);
        params.doc.text(startCase(translate("receipt.totalCost")), params.startX +78, params.startY + 75);
        params.doc.setFontSize(11);
       // params.doc.text(params.data.sttClientName === '' || params.data.sttBookedForName.toUpperCase() !== 'CUSTOMER APPS' ? "-" : totalBiaya, params.startX +78, params.startY + 79);
        params.doc.text(String(totalBiaya), params.startX +78, params.startY + 79);
    }

    // tampilkan biaya-biaya dibawah ini ketika client name customer apps atau client name kosong
    // Biaya Kirim 
    params.doc.setFont("Montserrat", "bold");
    params.doc.setFontSize(5);
    params.doc.text(translate("receipt.shippingCostV2"), params.startX +78, params.startY + 83);
    params.doc.text(":", params.startX + 91, params.startY + 83);
    params.doc.setFont("Montserrat", "normal");
    params.doc.text(String(totalBiayaKirim), params.startX + 92, params.startY + 83);

    // Biaya Lainnya
    params.doc.setFont("Montserrat", "bold");
    params.doc.text(translate("receipt.othersV2"), params.startX +78, params.startY + 86);
    params.doc.text(":", params.startX + 91, params.startY + 86);
    params.doc.setFont("Montserrat", "normal");
    params.doc.text(lainLain, params.startX + 92, params.startY + 86);


    // Pajak BM 
    if (showPajak) {
        params.doc.setTextColor('#78787A');
        params.doc.setFont("Montserrat", "bold");
        params.doc.text(translate("receipt.taxBm"), params.startX +78, params.startY + 89);
        params.doc.text(":", params.startX + 91, params.startY + 89);
        params.doc.setFont("Montserrat", "normal");
        params.doc.text(pajak, params.startX + 92, params.startY + 89);
    }

    return {
        ...params,
        startX: 0,
        startY: 0,
    }
}