import jsPDF from "jspdf";
import { PrintDataEntitas } from "@/domain/entities/Booking";

export const RecipientFooterSection = (
  params: {
    doc: jsPDF | any;
    bookingType: string;
    accountType: string;
    data: PrintDataEntitas;
    pieceData: any;
    barcode: string;
    vendorRefCode: string;
    barcodeValue: string;
    vendorRefCodeValue: string;
    logo: string;
    shipmentId: string;
    startX: number;
    startY: number;
  },
  translate: Function
) => {

  const leftMargin = params.startX + 4;

  params.doc.setTextColor("#333333");
  params.doc.setFontSize(5);

  const snkPositionY = params.startY + 146;
  params.doc.setFont("Montserrat", "normal");
  params.doc.text(translate("receipt.terms"), leftMargin, snkPositionY);
  params.doc.text(
    translate("receipt.info"),
    params.startX + 101,
    snkPositionY,
    null,
    null,
    "right"
  );

  const endContentY = snkPositionY + 3;

  return {
    ...params,
    startX: 0,
    startY: endContentY
  };
};