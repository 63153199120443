import { PrintDataEntitas } from "@/domain/entities/Booking";
import jsPDF from "jspdf";

export const originDestination = (
  params: {
    doc: jsPDF | any;
    startX: number;
    startY: number;
    data: PrintDataEntitas;
  },
  coor: {
    startX: number;
    startY: number;
  }
) => {
  // ORIGIN
  params.doc.setFont("Montserrat", "bold");
  params.doc.setTextColor("#333333");
  params.doc.setFontSize(14);
  params.doc.text(
    params.data.sttOriginCityId,
    coor.startX + 78,
    coor.startY + 7
  );

  // DESTINATION
  params.doc.setFont("Montserrat", "bold");
  params.doc.setTextColor("#333333");
  params.doc.setFontSize(28);
  params.doc.text(
    params.data.sttDestinationCityId,
    coor.startX + 78,
    coor.startY + 17
  );

  // LINE HORIZONTAL
  params.doc.setLineWidth(0.4);
  params.doc.line(
    coor.startX + 77,
    coor.startY + 20,
    coor.startX + 105,
    coor.startY + 20
  );
  const endContentY = 17;

  return {
    ...params,
    startX: 71,
    startY: endContentY,
  };
};