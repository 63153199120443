import { container } from "tsyringe";
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { PosClientList } from "@/domain/entities/PosClient";
import { PosClientPresenter } from "../presenters/PosClientPresenter";

export interface PosClientState {
  isLoading: boolean;
  isError: boolean;
  errorCause: string;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "pos_client_module"
})
class PosClientStore extends VuexModule implements PosClientState {
  isLoading = false;
  isError = false;
  errorCause = "";
  posList = new PosClientList([]);

  @Action
  getPosClientList(search: string) {
    this.setLoading(true);
    const presenter = container.resolve(PosClientPresenter);
    return presenter
      .getPosClientList(search)
      .then(res => {
        this.setPosClientList(res);
        this.setError(false);
        this.setErrorCause("");
        return res;
      })
      .catch(err => {
        this.setError(true);
        this.setErrorCause(err.response ? "server" : "internet");
        return;
      })
      .finally(() => this.setLoading(false));
  }

  @Mutation
  setPosClientList(list: PosClientList) {
    this.posList = list;
  }

  @Mutation
  setLoading(val: boolean) {
    this.isLoading = val;
  }

  @Mutation
  setError(val: boolean) {
    this.isError = val;
  }

  @Mutation
  setErrorCause(val: string) {
    this.errorCause = val;
  }
}

export const PosClientController = getModule(PosClientStore);
